<template>
  <validation-wrapper
    :errors="validationErrors"
    :fixed-height="fixedHeight"
    :class="{ 'w-100': fullWidth }"
  >
    <el-select
      v-loading="loading"
      v-el-select-lazy="loadMore"
      :value-key="valueKey"
      :value="localValue"
      :placeholder="placeholder"
      :class="{ 'w-100': fullWidth }"
      :clearable="clearable"
      :filterable="filterable"
      :filter-method="filter"
      @change="onChange"
    >
      <template #prefix>
        <slot name="prefix" />
      </template>

      <slot
        v-for="(item, index) in itemsDisplayed"
        name="option"
        :option="{ item }"
      >
        <el-option
          :key="`option:${item[valueKey]}:${index}`"
          :value="item"
          :label="item[optionLabel]"
        />
      </slot>
    </el-select>
  </validation-wrapper>
</template>

<script>
import ValidationWrapper from '@/vue_components/common/validation_wrapper'
import { PaginationMixin } from '@/vue_present/mixins/PaginationMixin'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { cloneDeep } from 'lodash'
import { ValidationChildMixin } from '@/vue_present/mixins/ValidationChildMixin'
import { props } from '@/vue_present/_base/MSelectLazyDeprecated/MSelectLazyDeprecatedProps'

/**
 * TODO: требуется больше кейсов использования
 */
export default {
  name: 'MSelectLazyDeprecated',
  components: { ValidationWrapper },

  mixins: [
    ValidationChildMixin,
    PaginationMixin,
    SpinnerHolder,
  ],

  model: {
    prop: 'value',
    event: 'change',
  },

  props,

  emits: [
    // в связке с watch:items,
    // позволяет использовать компонент не только автономно,
    // но и в качестве зависимого от $parent
    'syncItems',
    'change', 'update:value',
  ],

  data () {
    return {
      localValue: null,
      itemsDisplayed: [],
      itemsCache: [],
      searchQuery: '',
    }
  },

  computed: {
    filterable () {
      return Boolean(this.filterMethod)
    },
  },

  watch: {
    value: {
      handler (to) {
        if (!to) { return }
        if (!to[this.valueKey] && !to[this.optionLabel]) { return }
        this.localValue = cloneDeep(to)
        const newItems = this.findNewItems([to])
        this.addItems(newItems, true)
      },
      immediate: true,
    },

    items (to) {
      this.itemsCache = cloneDeep(to)
      this.itemsDisplayed = cloneDeep(to)
    },
  },

  created () {
    this.setLimit(this.loadingMethodLimit)
    this.fetch()
  },

  methods: {
    findNewItems (newItems = []) {
      return newItems.filter((item) =>
        !this.excludedItemsIds.includes(item.id) &&
        this.itemsCache.findIndex((option) => option.id === item.id) < 0
      )
    },

    fetch (searchQuery = '') {
      if (!this.loadingMethod) { return }

      const params = {
        ...this.loadingParams,
        ...this.pagination,
        [this.searchAttribute]: searchQuery,
        offset: Utils.ternary(searchQuery, 0, this.offset),
      }

      return this.withSpinner(this.loadingMethod(params)
        .then(
          /** @param {IResponseList} response */
          (response) => {
            if (!searchQuery) {
              this.setTotals(response.total_pages, response.total_items)
            }

            const newItems = this.findNewItems(response.data)
            this.addItems(newItems, Boolean(searchQuery))
          }))
    },

    loadMore () {
      const nextOffset = this.offset + this.limit

      if (this.searchQuery || nextOffset > this.totalItems) { return }

      this.setCurrentPage(nextOffset / this.limit + 1)
      this.fetch()
        .catch((err) => {
          Utils.reportError(
            t('list_message_short'),
            'MSelectLazyDeprecated:loadMore()'
          )(err)

          this.setCurrentPage(nextOffset / this.limit)
        })
    },

    addItems (newItems, unshift = false) {
      const action = Utils.ternary(unshift, 'unshift', 'push')

      this.itemsCache[action](...newItems)
      this.itemsDisplayed = cloneDeep(this.itemsCache)

      this.syncItems()
    },

    syncItems () {
      this.$emit('syncItems', this.itemsCache)
    },

    onChange (item) {
      const clonedItem = cloneDeep(item)
      this.$updateSync('value', clonedItem)
      this.$emit('change', clonedItem)
    },

    filter (searchQuery = '') {
      if (!this.filterable) { return }
      this.searchQuery = Utils.ternary(searchQuery.length >= 3, searchQuery, '')

      if (!this.searchQuery) {
        this.itemsDisplayed = cloneDeep(this.itemsCache)

        return
      }

      this.fetch(this.searchQuery)
        .then(() => {
          this.itemsDisplayed = this.itemsCache
            .filter(this.filterMethod(this.searchQuery))
        })
    },
  },
}
</script>
