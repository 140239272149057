<template>
  <div class="white-list-record-form flex flex-column gap-indent">
    <div class="white-list-record-form__title center">
      {{ title }}
    </div>

    <ip-address-input
      v-if="mode !== EDITOR_MODES.MASS_EDIT"
      :value="whiteListRecord.ipAddress"
      @input="whiteListRecord.setValue('ipAddress', $event)"
    />

    <m-textarea
      v-if="mode !== EDITOR_MODES.MASS_EDIT"
      :value="whiteListRecord.comment"
      :m-fixed-height="false"
      :placeholder="t$('comment')"
      :autosize="{ minRows: 3, maxRows: 15 }"
      @input="whiteListRecord.setValue('comment', $event)"
    />

    <m-checkbox
      v-if="mode !== EDITOR_MODES.NEW"
      :label="t$('active')"
      :value="whiteListRecord.active"
      class="white-list-record-form__active-checkbox"
      @input="whiteListRecord.setValue('active', $event)"
    />

    <slot />
  </div>
</template>

<script>
import IpAddressInput from '@/vue_apps/app_configuration/network/components/IpAddressInput.vue'
import MTextarea from '@/vue_present/_base/inputs/MTextarea/MTextarea.vue'
import MCheckbox from '@/vue_present/_base/inputs/MCheckbox/MCheckbox.vue'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'
import { WhiteListRecordLogic } from '@/vue_apps/app_configuration/network/entities/WhiteListRecordLogic'
import { EDITOR_MODES } from '@/vue_apps/app_configuration/network/const/const'

export default {
  name: 'WhiteListRecordForm',
  components: {
    IpAddressInput,
    MTextarea,
    MCheckbox,
  },

  mixins: [i18nScopeMixin],

  props: {
    whiteListRecord: WhiteListRecordLogic,
    mode: {
      type: String,
      default: EDITOR_MODES.NEW,
    },
  },

  data () {
    return {
      EDITOR_MODES,
    }
  },

  computed: {
    title () {
      return this.t$(`titles.${this.mode}`)
    },
  },

  created () {
    this.setI18nScope('network_vue')
  },
}
</script>
