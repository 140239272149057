import { MListService } from '@/_api/_requests/MListService'

export class MessagesList extends MListService {
  /**
   * @param {Object} initialFilters
   * @param {MPresenterBase} presenter
   * @param {string} cacheKey
   */
  constructor (initialFilters, presenter, cacheKey) {
    super(
      initialFilters,
      presenter,
      { cacheKey }
    )
  }
}
