import { request } from '@/lib/transport/request'
import { HTTP_METHODS } from '@/lib/transport/MRequest'
import { appointmentSourcesAdapter } from '@/api_entities/catalogs/appointment_sources/appointment_sources_adapter'

export const appointmentSourcesEndpoint = {
  fetchAll (sort_params) {
    const options = {
      type: HTTP_METHODS.POST,
      url: Routes.list_reception_appointment_sources_path(),
      data: {
        sort_params,
      },
    }

    return request(options).promise
  },

  get (id) {
    const options = {
      type: HTTP_METHODS.GET,
      url: Routes.reception_appointment_sources_path(id),
    }

    return request(options)
  },

  submit (data) {
    return data.id
      ? appointmentSourcesEndpoint.update(data)
      : appointmentSourcesEndpoint.create(data)
  },

  update (data) {
    const options = {
      type: HTTP_METHODS.PATCH,
      url: Routes.reception_appointment_source_path(data.id),
      data: appointmentSourcesAdapter.toServer(data),
    }

    return request(options, appointmentSourcesAdapter.toClient).promise
  },

  create (data) {
    const options = {
      type: HTTP_METHODS.POST,
      url: Routes.reception_appointment_sources_path(),
      data: appointmentSourcesAdapter.toServer(data),
    }

    return request(options, appointmentSourcesAdapter.toClient).promise
  },

  destroy (id) {
    const options = {
      type: HTTP_METHODS.DELETE,
      url: Routes.reception_appointment_source_path(id),
    }

    return request(options).promise
  },
}
