import { createVueApp } from '@/vue_components/create_vue_app'
import { createStore } from '@/vue_components/store/store'
import router from '@/vue_components/router/router'
import MessagesConfiguration from '@/vue_apps/app_configuration/messages/MessagesConfiguration.vue'

$document.on('rez/messages/configurations', () => {
  const el = '#vue_messages_configuration'

  createVueApp({
    el,
    name: 'MessagesConfigurationRoot',
    store: createStore(),
    router,
    render: (h) => h(MessagesConfiguration),
  })
})
