import { createVueApp } from '@/vue_components/create_vue_app'
import router from '@/vue_components/router/router'
import ReferralsCatalog from '@/vue_apps/catalogs_root/Referrals/ReferralsCatalog.vue'

let vueApp = null
$document.on('rez/referrals/index rez/referrals/new rez/referrals/edit', () => {
  const el = '#vue_referrals_catalog_app'
  if (vueApp?.destroy) { vueApp.destroy() }
  if (!Services.security.canViewReferral) { return }

  vueApp = createVueApp({
    el,
    name: 'ReferralsCatalogApp',
    router,
    render: (h) => h(ReferralsCatalog),
  })
})
