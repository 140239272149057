/**
 * Класс-утилита для работы с рендер функциями
 */
export class RenderFunctionHelpers {
  static createTreeFromArray = (data) => {
    // todo
  }

  static getIconClass = (folderCondition, expanded, icons = {
    folderClass: 'fa-folder warning',
    folderOpenedClass: 'fa-folder-open warning',
    itemClass: 'fa-file-medical',
  }) => {
    const iconClass = folderCondition
      ? expanded
        ? icons.folderOpenedClass
        : icons.folderClass
      : icons.itemClass

    return `fad fa-fw mr-5 font-size-17 ${iconClass}`
  }
}
