<template>
  <div class="white-list-editor">
    <white-list-record-form
      v-loading="isSubmitProcessOn"
      :white-list-record="whiteListRecord"
      :mode="mode"
    >
      <div class="flex align-center gap-indent-small">
        <m-button
          v-tooltip="t$(submitBtnParams.message)"
          :disabled="!canSubmit"
          :template="submitBtnParams.template"
          @click="submit"
        />

        <m-button
          v-tooltip="t$('cancel')"
          :disabled="!canSubmit"
          icon="undo"
          @click="undoForm"
        />

        <m-button-delete
          v-if="mode === EDITOR_MODES.MASS_EDIT"
          :disabled="!canSubmit"
          class="white-list-editor__delete-btn"
          @yes="deleteMany"
        />
      </div>
    </white-list-record-form>
  </div>
</template>

<script>
import WhiteListRecordForm from '@/vue_apps/app_configuration/network/components/WhiteListRecordForm.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MButtonDelete from '@/vue_present/_base/buttons/MButtonDelete/MButtonDelete.vue'
import { WhiteListRecordApi } from '@/vue_apps/app_configuration/network/entities/WhiteListRecordApi'
import { EDITOR_MODES } from '@/vue_apps/app_configuration/network/const/const'
import { MNetworkPresenter } from '@/_api/MNetwork/MNetworkPresenter'
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'

export default {
  name: 'WhiteListEditor',
  components: {
    WhiteListRecordForm,
    MButton,
    MButtonDelete,
  },

  mixins: [i18nScopeMixin],

  props: {
    selectedWhiteListRecordsIds: {
      type: Array,
      default: () => [],
    },
    canSubmit: Boolean,
  },

  emits: [
    'onSubmitted',
    'onUndoChanges',
    'onRegisterUpdateData',
    'onDeletedMany',
  ],

  data () {
    return {
      whiteListRecord: new WhiteListRecordApi(),
      isSubmitProcessOn: false,
      EDITOR_MODES,
    }
  },

  computed: {
    mode () {
      const count = this.selectedWhiteListRecordsIds.length

      return count
        ? count > 1
          ? EDITOR_MODES.MASS_EDIT
          : EDITOR_MODES.EDIT_ONE
        : EDITOR_MODES.NEW
    },

    isModeNew () {
      return this.mode === EDITOR_MODES.NEW
    },

    submitBtnParams () {
      return {
        template: this.isModeNew
          ? 'add'
          : 'short_edit',
        message: this.isModeNew
          ? 'add'
          : 'edit',
      }
    },
  },

  created () {
    this.setI18nScope('network_vue')
  },

  mounted () {
    this.$emit('onRegisterUpdateData', this.updateWhiteListRecordData)
  },

  methods: {
    undoForm () {
      this.mode === EDITOR_MODES.NEW
        ? this.whiteListRecord.reset()
        : this.$emit('onUndoChanges')
    },

    updateWhiteListRecordData (newData = {}) {
      this.whiteListRecord = new WhiteListRecordApi(newData)
    },

    async submit () {
      if (!this.canSubmit) { return }

      this.isSubmitProcessOn = true

      const res = await this.sendRequest()

      this.isSubmitProcessOn = false
      this.handleResponse(res)
    },

    handleResponse (res) {
      if (res?.errors) { return }

      this.$emit('onSubmitted')
    },

    async sendRequest () {
      if (this.mode === EDITOR_MODES.NEW) {
        return await this.whiteListRecord.add()
      }

      if (this.mode === EDITOR_MODES.EDIT_ONE) {
        return await this.whiteListRecord.edit()
      }

      return await new MNetworkPresenter().updateMany({
        ids: this.selectedWhiteListRecordsIds,
        active: this.whiteListRecord.active,
      })
    },

    async deleteMany () {
      if (!this.canSubmit) { return }

      this.isSubmitProcessOn = true

      const res = await new MNetworkPresenter().destroyMany(this.selectedWhiteListRecordsIds)

      this.isSubmitProcessOn = false

      if (res?.errors) { return }

      this.$emit('onDeletedMany')
    },
  },
}
</script>
