import { EntryPackEntriesList } from '@/vue_apps/catalogs_root/EntryPacks/entities/EntryPackEntriesList'

/** @implements IEntryPack */
export class EntryPack {
  /**  @type {number|null} */
  id = null

  comment = ''

  name = ''

  /**  @type {EntryPackEntriesList} */
  entries

  /**  @param {IEntryPackRaw?} data */
  constructor (data = {}) {
    this.id = data.id
    this.name = data.name
    this.comment = data.comment
    this.entries = new EntryPackEntriesList(data.entries)
  }
}
