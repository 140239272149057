<template>
  <div
    v-loading="loading"
    class="attraction-sources-form flex-form"
  >
    <div class="form-label">
      <label for="attraction_source_title">
        {{ t('title') }}
      </label>

      <required-mark />
    </div>

    <div class="form-value">
      <validation-wrapper
        :errors="validationMessages.title"
        fixed-height
      >
        <el-input
          id="attraction_source_title"
          v-model="tempActiveItem.title"
          name="attraction_source[title]"
          clearable
          :disabled="disabled || isSystem"
        />
      </validation-wrapper>
    </div>

    <div class="form-label">
      <label
        v-tooltip="t('activerecord.attributes.attraction_source.phone_tooltip')"
        for="attraction_source_phone"
      >
        {{ t('activerecord.attributes.attraction_source.phone') }}
      </label>

      <explanation-mark :text="t('activerecord.explanation.text')" />
    </div>

    <!-- Телефон рекламной кампании -->
    <m-input
      v-model="tempActiveItem.phone"
      class="w-100"
      :errors="validationMessages.phone"
      :disabled="disabled || isSystem"
      validator-name="phone"
      @registerValidator="onRegisterValidator"
    />

    <div class="attraction-sources-form_footnote mb-15 w-100">
      <span>
        <i class="fad fa-cogs primary" />
        {{ t('attraction_sources_catalog_footnote') }} <a href="/reception/appointment_sources">{{ t('appointment_sources') }}</a>
      </span>
    </div>

    <div class="attraction-sources-form__footer flex flex-grow-1 justify-content-between">
      <button
        type="button"
        :disabled="disabled"
        class="btn btn-sm btn-with-icon"
        :class="btnClass"
        @click="onSubmit"
      >
        <span class="btn-with-icon_icon fad fa-save" />
        <span>{{ t('save') }} </span>
      </button>

      <attraction-sources-form-destroy
        v-if="isEditMode && !isSystem"
        :can-manage="canManage"
        :disabled="disabled"
        :is-system="isSystem"
        :items="items"
        :active-item="activeItem"
        @onDestroy="onDestroy"
      />
    </div>
  </div>
</template>

<script>
import RequiredMark from '@/vue_components/common/required_mark.vue'
import ValidationWrapper from '@/vue_components/common/validation_wrapper.vue'
import ExplanationMark from '@/vue_components/common/explanation_mark.vue'
import {
  createDefaultAttractionSource,
} from '@/vue_apps/catalogs_root/attraction_sources/const/createDefaultAttractionSource'
import { cloneDeep } from 'lodash'
import {
  AttractionSourceValidationMixin,
} from '@/vue_apps/catalogs_root/attraction_sources/mixins/AttractionSourceValidationMixin'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { enrichSubmitted } from '@/vue_apps/catalogs_root/attraction_sources/logic/enrichSubmited'
import { SYSTEM_ATTRACTION_SOURCE } from '@/vue_apps/catalogs_root/attraction_sources/const/const'
import AttractionSourcesFormDestroy
  from '@/vue_apps/catalogs_root/attraction_sources/components/AttractionSourcesForm/AttractionSourcesFormDestroy.vue'
import { MODES } from '@/vue_apps/catalogs_root/_catalog_base/const/const'
import { attractionSourcesPresenter } from '@/api_entities/catalogs/attraction_sources/attraction_sources_presenter'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'

export default {
  name: 'AttractionSourcesForm',

  components: {
    MInput,
    AttractionSourcesFormDestroy,
    ExplanationMark,
    ValidationWrapper,
    RequiredMark,
  },

  mixins: [
    AttractionSourceValidationMixin,
    SpinnerHolder,
    ValidationParentMixin,
  ],

  props: {
    canManage: PropsTypes.Boolean(),

    mode: PropsTypes.String(),

    activeItem: PropsTypes.Object(createDefaultAttractionSource()),

    attractionSources: PropsTypes.Array(),
  },

  emits: [
    'successSubmit',
    'successDestroy',
  ],

  data () {
    return {
      tempActiveItem: cloneDeep(this.activeItem),
      maskLength: null,
    }
  },

  computed: {
    disabled () {
      return !this.canManage
    },

    isSystem () {
      return this.activeItem.system === SYSTEM_ATTRACTION_SOURCE
    },

    btnClass () {
      return this.activeItem && this.activeItem.id
        ? 'btn-warning'
        : 'btn-success'
    },

    isEditMode () {
      return this.mode === MODES.EDIT
    },
  },

  watch: {
    activeItem (to) {
      this.reset(to)
    },
  },

  created () {
    this.reset(this.activeItem)
  },

  methods: {
    reset (to = createDefaultAttractionSource()) {
      this.tempActiveItem = cloneDeep(to)
      this.resetValidations()
    },

    async onSubmit () {
      if (this.hasErrors()) { return }

      let response
      try {
        response = await attractionSourcesPresenter.submit(cloneDeep(this.tempActiveItem))
      } catch (messages) {
        this.resetValidations(messages)
        this.validationMessages = { ...this.validationMessages, ...messages }

        return
      }

      this.successSubmit(response)
    },

    successSubmit (response) {
      const submittedItem = enrichSubmitted(response, this.tempActiveItem.id)
      if (!this.isEditMode) { this.reset() }
      this.$emit('successSubmit', submittedItem)
    },

    async onDestroy (replacementSourceId) {
      await this.withSpinner(attractionSourcesPresenter.destroy(this.activeItem.id, replacementSourceId))
      this.$emit('successDestroy', this.activeItem.id)
    },
  },
}
</script>
