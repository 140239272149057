import { MReferralPresenter } from '@/_api/MReferral/MReferralPresenter'
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_LIMIT,
  DEFAULT_TOTAL_ITEMS,
  DEFAULT_TOTAL_PAGES,
} from '@/vue_components/store/modules/filters_base'
import { ReferralListItem } from '@/vue_apps/catalogs_root/Referrals/entities/ReferralListItem'
import { MLocalStorage } from '@/_api/_storage/MLocalStorage'

export class ReferralList {
  constructor () {
    this.__storage = new MLocalStorage('ReferralList')
    this.__cache = this.__storage.restore() || {}

    this.data = []
    this.totalItems = DEFAULT_TOTAL_ITEMS
    this.totalPages = this.__cache?.totalPages || DEFAULT_TOTAL_PAGES

    this.withInternal = this.__cache?.withInternal || false
    this.currentPage = this.__cache?.currentPage || DEFAULT_CURRENT_PAGE
  }

  get params () {
    return {
      withInternal: this.withInternal,
      offset: (this.currentPage - 1) * DEFAULT_LIMIT,
      limit: DEFAULT_LIMIT,
    }
  }

  get withInternalValue () {
    return this.withInternal
  }

  set withInternalValue (value) {
    this.withInternal = value
    this.currentPage = DEFAULT_CURRENT_PAGE
  }

  async fetchAll () {
    const referrals = await new MReferralPresenter().list(this.params)
    if (referrals?.errors) { return this }

    const { data, totalItems, totalPages } = referrals
    this.data = (data || []).map((item) => new ReferralListItem(item))
    this.totalItems = totalItems || DEFAULT_TOTAL_ITEMS
    this.totalPages = totalPages || DEFAULT_TOTAL_PAGES

    this.__storage.save({
      ...this.params,
      currentPage: this.currentPage,
      totalPages: this.totalPages,
    })

    return this
  }
}
