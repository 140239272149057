import { EntryPackEntry } from '@/vue_apps/catalogs_root/EntryPacks/entities/EntryPackEntry'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'

/** @implements IEntryPackEntriesList */
export class EntryPackEntriesList {
  /**  @type {EntryPackEntry[]} */
  items = []

  /** @param {IEntryPackEntryRaw[]} data */
  constructor (data) {
    this.items = (data || []).map(
      ({ entryType, amount, id: entryPackEntryId, allowedClinics }) =>
        new EntryPackEntry({ ...entryType, amount, entryPackEntryId, allowedClinics })
    )
  }

  get totalAmount () {
    return this.items.reduce((acc, { amount }) => acc + amount, 0)
  }

  get totalSum () {
    return vueFilters.currency(this.items.reduce((acc, { sum }) => acc + sum, 0))
  }

  get restrictedByClinic () {
    return Boolean(
      this.items
        .some(({ allowedClinics, destroyed }) => allowedClinics.length && !destroyed)
    )
  }

  /**
   * @param {IEntryPackEntryType} entryType
   * @return EntryPackEntry
   */
  appendEntryType (entryType) {
    const entryPackEntry = this.items.find(({ entryTypeId }) => entryTypeId === entryType.id)

    // если уже есть такой EntryPackEntry
    if (entryPackEntry?.destroyed === false) {
      entryPackEntry.setAmount(entryPackEntry.amount + 1)

      return entryPackEntry
    }

    // если уже есть такой "УДАЛЁННЫЙ" EntryPackEntry
    if (entryPackEntry?.destroyed) {
      entryPackEntry.restore()
      entryPackEntry.setAmount(1)

      return entryPackEntry
    }

    // если нет EntryPackEntry
    const newEntryPackEntry = new EntryPackEntry(entryType)
    this.items.push(newEntryPackEntry)
    newEntryPackEntry.fetchAllowedClinics()

    return newEntryPackEntry
  }

  clearDestroyed () {
    this.items = this.items.filter(({ destroyed }) => !destroyed)
  }

  reindex (entries) {
    const entryTypeIdToIdMap =
      entries.reduce((acc, { entryType, id }) => {
        acc[entryType.id] = id

        return acc
      }, {})

    this.items.forEach((item) => {
      if (item.entryPackEntryId) { return }
      item.entryPackEntryId = entryTypeIdToIdMap[item.entryTypeId]
    })
  }
}
