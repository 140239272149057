<template>
  <div class="front-book">
    <m-panel
      class="front-book__left-panel"
      title="Меню"
      collapsable
      is-parent-control
      :is-collapsed.sync="isMenuCollapsed"
      body-css-classes="p-0"
    >
      <m-menu
        router
        :is-collapsed="isMenuCollapsed"
        :menu-categories="menu"
      />
    </m-panel>

    <m-panel
      class="front-book__right-panel"
      :icon="$route.meta.icon"
      :title="$route.meta.label"
    >
      <router-view />
    </m-panel>
  </div>
</template>

<script>
import MPanel from '@/vue_present/_base/MPanel/MPanel'
import MMenu from '@/vue_present/_base/MMenu/MMenu'
import { menu } from '@/vue_present/_FrontBook/router/menu'

export default {
  name: 'FrontBook',
  components: { MMenu, MPanel },
  data () {
    return {
      menu,
      isMenuCollapsed: false,
    }
  },
}
</script>
