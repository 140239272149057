import { createVueApp } from '@/vue_components/create_vue_app'
import MessagesApp from '@/vue_apps/CommunicationModule/Messages/MessagesApp.vue'
import { getDefaultMessagesFilters } from '@/vue_apps/CommunicationModule/Messages/store/getDefaultMessagesFilters'
import { messagesTableSchema } from '@/vue_apps/CommunicationModule/Messages/store/messagesTableSchema'
import { MDeliveryPresenter } from '@/_api/Deliveries/MDeliveryPresenter'

/* import router from '@/vue_components/router/router' // todo: когда будут переделываться звонки */

let vueApp = null
$(document).on('rez/messages/index', () => {
  if (vueApp?.destroy) { vueApp.destroy() }

  vueApp = createVueApp({
    el: '#vue_communication_messages_app',
    name: 'MessagesAppRoot',
    /* router, // todo: когда будут переделываться звонки */
    render: (h) => h(MessagesApp, {
      props: {
        defaultFilters: getDefaultMessagesFilters(),
        tableSchema: messagesTableSchema,
        presenter: new MDeliveryPresenter(),
      },
    }),
  })
})
