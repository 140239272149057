<template>
  <si-table-generator
    :table-schema="configurationsTableSchema"
    :items="smsRoutes"
    :active-item-id="activeItemId"
    @onItemClick="setSelectedPage"
  />
</template>

<script>
import {
  configurationsTableSchema,
} from '@/vue_apps/app_configuration/messages/_messages_configurations_base/helpers/configurationsTableSchema'
import SiTableGenerator from '@/vue_components/sort_items/SiTableGenerator/SiTableGenerator.vue'
import {
  getRouteParamsMapper,
  getRoutesWithoutLast,
} from '@/vue_apps/app_configuration/messages/_messages_configurations_base/helpers/menuHelpers'
import { routes } from '@/vue_apps/app_configuration/messages/router/routes'

export default {
  name: 'SmsConfigurationsMenu',
  components: { SiTableGenerator },

  data () {
    return {
      configurationsTableSchema,
      smsRoutes: getRouteParamsMapper(getRoutesWithoutLast(routes)),
      selectedRoute: { id: this.$route.name || routes[0].name },
    }
  },

  computed: {
    activeItemId () {
      return this.selectedRoute && this.selectedRoute.id
    },
  },

  methods: {
    setSelectedPage (settingsPage = {}) {
      this.selectedRoute = settingsPage
      this.$emit('openSettingsPage', settingsPage)
    },
  },
}
</script>
