<template>
  <basic-tooltip :text="text">
    <abbr
      class="required-mark"
      :class="{'text-decoration-none': !underline}"
    >
      <slot>*</slot>
    </abbr>
  </basic-tooltip>
</template>

<script>
import BasicTooltip from '@/vue_components/common/basic_tooltip'

export default {
  name: 'RequiredMark',
  components: { BasicTooltip },
  props: {
    text: {
      type: String,
      default: t('activerecord.required.text'),
    },

    underline: Boolean,
  },
}
</script>
