
export const referralsTableSchema = Object.freeze({
  options: { empty: true },
  headers: {
    iconOrAvatar: '',
    fullName: '',
    phone: '',
    companyTitle: '',
  },
})
