import {
  checkupSpecialistRoute,
  checkupTestRoute,
} from '@/vue_apps/catalogs_root/CheckupCatalogs/router/checkupCatalogsRoutes'

export const navRoutes = Object.freeze([
  {
    items: [
      checkupSpecialistRoute,
      checkupTestRoute,
    ],
  },
])
