import { createVueApp } from '@/vue_components/create_vue_app'

let vueApp = null
const createFixedDocumentApp = (el = '#vue_fixed_document_root') => {
  if (!document.querySelector(el)) { return }
  if (vueApp?.destroy) { vueApp.destroy() }

  vueApp = createVueApp({
    el,
    name: 'FixedDocumentApp',
    render: (h) => h('router-view'),
  })
}

$document.on('rez/fixed_document', () => createFixedDocumentApp())
