import formatter from '@/lib/formatters/formatter'

export class ReferralListItem {
  /**
   * @param {TReferralListItem} referral
   */
  constructor (referral) {
    this.id = referral.id
    this.surname = referral.surname
    this.name = referral.name
    this.secondName = referral.secondName
    this.phone = referral.phone
    this.companyTitle = referral.companyTitle
    this.userId = referral.userId
    this.avatarTag = referral.avatarTag
  }

  get fullName () {
    return formatter.formatValue(this, ['user:fullName'])
  }
}
