import { createVueApp } from '@/vue_components/create_vue_app'
import AppointmentSources from '@/vue_apps/catalogs_root/appointment_sources/AppointmentSources.vue'

$document.on('rez/reception/appointment_sources', () => {
  const el = '#vue_reception_appointment_sources'

  createVueApp({
    el,
    name: 'AppointmentSourcesCatalogRoot',
    render: (h) => h(AppointmentSources),
  })
})
