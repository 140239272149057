import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import { DEFAULT_LIMIT } from '@/vue_components/store/modules/filters_base'

export const props = {
  /**
   * @param {Number|String} - ключевой атрибут объекта value
   */
  valueKey: PropsTypes.String('id'),
  /**
   * @param {String} - атрибут, который будет использован как текст элемента списка
   */
  optionLabel: PropsTypes.String('title'),

  /**
   * @param {Function} - метод, который будет вызываться при
   * создании компонента и при дальнейшей ленивой загрузке
   */
  loadingMethod: PropsTypes.Function(),
  /**
   * @param {Object} - объект параметров, отправляемый вместе с loadingMethod
   */
  loadingParams: PropsTypes.Object(),
  /**
   * @param {Number} - Количество объектов, получаемых за один запрос
   */
  loadingMethodLimit: PropsTypes.Number(DEFAULT_LIMIT),

  /**
   * @param {String} - атрибут для поиска, отправляется вместе с запросом
   */
  searchAttribute: PropsTypes.String('search_query'),

  /**
   * @param {Object} - текущее значение в $parent
   */
  value: PropsTypes.Custom([Object], null),
  /**
   * @param {Object} - список в $parent
   */
  items: PropsTypes.Array(),

  fixedHeight: PropsTypes.Boolean(), /** @param {Boolean} - фиксированная высота для сообщения валидации */
  fullWidth: PropsTypes.Boolean(), /** @param {Boolean} - ширина 100% от родительского */
  clearable: PropsTypes.Boolean(), /** @param {Boolean} - очистка по клику на кнопку крестика */
  placeholder: PropsTypes.String(t('search')),

  /**
   * @param {Function} - метод вызываемый во время поиска
   * @example (searchQuery) => (item) =>
   *   item.title.toLowerCase().includes(searchQuery.toLowerCase())
   */
  filterMethod: PropsTypes.Function(),

  /**
   * @param {(Number|String)[]} - эти id не будут показываться в списке
   * TODO: возможно требуется доработка. Нет реактивного поведения
   */
  excludedItemsIds: PropsTypes.Array(),
}
