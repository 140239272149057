import {
  expensesRoute,
  ordersRoute,
  paymentsRoute,
} from '@/vue_apps/FinanceModule/FinanceModuleIndex/router/financeModuleRoutes'

export const navRoutes = () => Object.freeze([
  {
    items: [ordersRoute],
    vertical: true,
  },
  {
    items: Services.security.canViewPayment && [paymentsRoute],
    vertical: true,
  },
  {
    items: Services.security.canViewExpense && [expensesRoute],
    vertical: true,
  },
])
