
export const attractionSourcesTableSchema = {
  options: {},
  headers: {
    icon: '',
    title: t('title'),
  },
}

export const SYSTEM_ATTRACTION_SOURCE = 'unspecified'
export const DELETED_SOURCE_DEFAULT = t('source_not_specified').toLowerCase()
