<template>
  <div class="communication-messages__filters flex flex-wrap gap-indent-small">
    <!-- Дата/Период-->
    <m-date-picker-buttons
      v-if="useDatePickerButtons"
      v-model="vmPeriod"
      :m-fixed-height="false"
      :use-only="datePickerButtons"
    />
    <m-date-picker
      v-model="vmPeriod"
      type="daterange"
      :m-fixed-height="false"
      class="filter__date-picker"
    />

    <!-- Все каналы -->
    <m-select
      v-model="vmDeliveryType"
      :placeholder="t('sms.allChannels')"
      :m-fixed-height="false"
      :items="deliveryTypesItems"
      use-custom-result="simple"
      use-icon
    >
      <template #prefix>
        <m-icon
          v-if="vmDeliveryType"
          :icon="vmDeliveryType"
          :use-brand="vmDeliveryType === 'whatsapp'"
          :color="vmDeliveryType === 'whatsapp' ? 'success' : 'primary'"
        />
        <m-icon
          v-else
          icon="comments"
          color=""
        />
      </template>
    </m-select>

    <!-- Все статусы-->
    <m-select
      v-model="vmStatus"
      :placeholder="t('all_statuses')"
      :m-fixed-height="false"
      :items="statusesItems"
      use-custom-result="simple"
      use-icon
    >
      <template #prefix>
        <m-icon
          v-if="vmStatus"
          :icon="MESSAGE_DELIVERY_STATUS_ICONS[vmStatus].icon"
          :color="MESSAGE_DELIVERY_STATUS_ICONS[vmStatus].color"
        />
        <m-icon
          v-else
          icon="allMessageStatuses"
          color=""
        />
      </template>
    </m-select>

    <!-- Все типы -->
    <m-select-lazy
      v-model="vmMessageType"
      :fetch-method="filtersPresenter.fetchTypes"
      :placeholder="t('all_types')"
      :m-fixed-height="false"
      use-first-time-fetch
      use-custom-result="simple"
    />

    <!-- Все ошибки -->
    <template v-if="useErrorsFilter">
      <m-select
        v-if="isErrorsVisible"
        v-model="vmError"
        :placeholder="t('all_errors')"
        :m-fixed-height="false"
        :items="filteredErrors"
        filterable
        :custom-filter="defaultSearchFilter"
        use-custom-result="simple"
      />
    </template>

    <m-button
      template="short_refresh"
      @click="$emit('refresh')"
    />

    <m-button
      type="warning"
      :tooltip="t('reset')"
      no-use-fw
      icon="no-filter"
      @click="$emit('reset')"
    />
  </div>
</template>

<script>
import MDatePickerButtons from '@/vue_present/_base/MDatePickerButtons/MDatePickerButtons.vue'
import MDatePicker from '@/vue_present/_base/inputs/MDatePicker/MDatePicker.vue'
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import {
  MESSAGE_DELIVERY_STATUS_ICONS,
  MESSAGE_DELIVERY_STATUSES,
} from '@/vue_apps/CommunicationModule/Messages/const/const'
import { DATE_PICKER_BUTTONS } from '@/vue_present/_base/MDatePickerButtons/helpers'
import { DEFAULT_FILTRATION_DEBOUNCE } from '@/vue_components/store/modules/filters_base'
import { MMessagesFiltersPresenter } from '@/_api/Deliveries/MessagesFilters/MMessagesFiltersPresenter'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import { messageAppDrillingProps } from '@/vue_apps/CommunicationModule/Messages/props/MessageAppDrillingProps'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { defaultSearchFilter } from '@/helpers/lambda'

const localBinding = (field) => ({
  get () { return this.filters[field] },
  set (value) { this.$emit('setFilter', { field, value }) },
})

export default {
  name: 'MessagesFilters',
  components: { MIcon, MSelect, MButton, MSelectLazy, MDatePicker, MDatePickerButtons },

  props: {
    ...messageAppDrillingProps,

    filters: PropsTypes.Object(),
    filtersEmitDebounce: PropsTypes.Number(DEFAULT_FILTRATION_DEBOUNCE),
  },

  emits: [
    'refresh',
    'reset',
    'setFilter',
    'filtersUpdated',
  ],

  data () {
    return {
      filtersTimeout: null,

      filtersPresenter: new MMessagesFiltersPresenter(),
      errorsItems: [],
      statusesItems: [],
      deliveryTypesItems: [],

      defaultSearchFilter,

      MESSAGE_DELIVERY_STATUS_ICONS,
    }
  },

  computed: {
    vmPeriod: localBinding('period'),
    vmDeliveryType: localBinding('deliveryType'),
    vmStatus: localBinding('status'),
    vmMessageType: localBinding('messageType'),
    vmError: localBinding('error'),

    isErrorsVisible () {
      return this.vmStatus?.id !== MESSAGE_DELIVERY_STATUSES.DELIVERED
    },

    datePickerButtons () {
      return [
        DATE_PICKER_BUTTONS.MONTH,
        DATE_PICKER_BUTTONS.WEEK,
        DATE_PICKER_BUTTONS.TODAY,
        DATE_PICKER_BUTTONS.YESTERDAY,
      ]
    },

    filteredErrors () {
      if (!this.vmDeliveryType) { return this.errorsItems }

      return this.errorsItems.filter(({ type }) => !type || type === this.vmDeliveryType)
    },
  },

  watch: {
    vmStatus (to) {
      if (to === MESSAGE_DELIVERY_STATUSES.DELIVERED) { this.vmError = null }
    },

    vmDeliveryType (to) {
      this.filtersPresenter.fetchStatuses(to)
        .then((data) => { this.statusesItems = data })
    },

    filters () {
      if (this.filtersTimeout) {
        clearTimeout(this.filtersTimeout)
        this.filtersTimeout = null
      }

      this.filtersTimeout = setTimeout(() => {
        this.$emit('filtersUpdated')
      }, DEFAULT_FILTRATION_DEBOUNCE)
    },
  },

  created () {
    this.filtersPresenter.fetchDeliveryTypes()
      .then((data) => { this.deliveryTypesItems = data })

    this.filtersPresenter.fetchStatuses(this.vmDeliveryType)
      .then((data) => { this.statusesItems = data })

    this.filtersPresenter.fetchErrors()
      .then((data) => { this.errorsItems = data })
  },
}
</script>
