const BRANCH = Object.freeze({
  MEDICINE: 10,
  STOMATOLOGY: 20,
  CUSTOM: 30,
})

export const getBranchTemplate = (value) => {
  const getBranchTitle = (value) => {
    switch (value) {
      case BRANCH.MEDICINE: return t('branch_medicine')
      case BRANCH.STOMATOLOGY: return t('branch_stomatology')
      default: return t('branch_custom')
    }
  }

  return `Medods ${getBranchTitle(value)}`
}

export const getVersionTemplate = (version, build) => {
  if (!build) {
    return version
  }

  return `${version} build ${build}`
}

export const getLicCountTemplate = (value) => {
  return value
}

export const getPhonesTemplate = (phones = []) => {
  return phones
    .map((phone) => `<a class="primary-important" href="tel:${phone}">${phone}</a>`)
    .join(', ')
}

export const getEmailsTemplate = (emails = []) => {
  return emails
    .map((emails) => `<a class="primary-important" href="mailto:${emails}" target="_blank">${emails}</a>`)
    .join(', ')
}

export const getSupportHours = (value) => {
  return value
}

export const getHrefTemplate = ({ url, text }) =>
  `<a href="${url}" target="_blank" class="primary-important">${text}</a>`
