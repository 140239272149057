<template>
  <div class="checkup-catalogs">
    <m-panel
      icon="checkups"
      class="checkup-catalogs__submenu"
      :title="t('checkups.catalog.title')"
      body-css-classes="p-0"
      :use-type-by-route="false"
    >
      <m-menu
        class="checkup-catalogs__menu"
        :menu-categories="menu"
        router
      />
    </m-panel>

    <router-view
      :id="+$route.params.id"
      :kind="$route.params.kind"
      class="checkup-catalogs__content"
    />

    <router-view
      :id="+$route.params.id"
      :kind="$route.params.kind"
      name="editor"
      class="checkup-catalogs__editor"
    />
  </div>
</template>

<script>
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import MMenu from '@/vue_present/_base/MMenu/MMenu.vue'
import { navRoutes } from '@/vue_apps/catalogs_root/CheckupCatalogs/router/navRoutes'

export default {
  name: 'CheckupCatalogs',
  components: { MMenu, MPanel },

  data () {
    return {
      menu: navRoutes,
    }
  },
}
</script>
