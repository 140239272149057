import { FlatDepthTreeNode } from '@/vue_present/Reuse/CatalogTree/store/forest/FlatDepthTree/FlatDepthTreeNode'

/**
 * @implements IFlatDepthTree
 */
export class FlatDepthTree {
  /** @type {Record<number, FlatDepthTreeNode>} */
  _treeMap = {}

  /** @type {FlatDepthTreeNode[]} */
  treeData = []

  treeSelectedNodes = new Set()

  /**
   * @param {TFlatDepthTreeNodeRaw[]} nodes
   */
  _buildTree (nodes = []) {
    const sortedNodes =
      nodes.sort((a, b) => a.depth - b.depth)

    /** @type {FlatDepthTreeNode} */
    let root = null
    sortedNodes.forEach((node, index) => {
      this._treeMap[node.id] = new FlatDepthTreeNode(this, node, this._treeMap[node.parentId])
      if (index === 0) { root = this._treeMap[node.id] }

      if (node.parentId && this._treeMap[node.parentId]?.children) {
        this._treeMap[node.parentId].children.push(this._treeMap[node.id])
      }
    })

    root.sort()

    this.treeData = [root]
  }

  /** @param {FlatDepthTreeNode} node */
  _deleteNodeFromCache (node) {
    delete this._treeMap[node.id]
  }

  resetSelection () {
    this.treeData[0].select(false)
    this.treeSelectedNodes = new Set()

    return []
  }
}
