
export const CatalogWrapperPaginationProps = {
  currentPage: {
    type: Number,
    default: 1,
  },

  totalPages: {
    type: Number,
    default: 1,
  },
}
