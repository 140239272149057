<template>
  <div class="messages-configurations col-container catalog-container">
    <m-panel
      :title="t('sms.messengersSettings')"
      :icon="'fa-comment-lines'"
      head-css-classes="primary"
      body-css-classes="p-0"
      m-panel-class=""
    >
      <slot name="settingsList" />
    </m-panel>

    <m-panel
      :title="$route.meta.label"
      icon=""
      head-css-classes="success"
    >
      <slot name="settingPage" />
    </m-panel>
  </div>
</template>

<script>
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'

export default {
  name: 'ConfigurationsBase',
  components: { MPanel },
}
</script>
