import { MRequestWrapper } from '@/_api/_requests/MRequestWrapper'
import { snakeToCamel } from '@/_api/_requests/helpers'

/** @implements IFlatDepthTreeApiPresenter */
export class MProtocolEntityPresenter {
  #entity

  constructor (entity) {
    if (!entity) {
      throw new Error('Сущность не указана')
    }

    this.#entity = entity
  }

  fetch () {
    return new MRequestWrapper({
      type: 'get',
      location: `MProtocolEntityPresenter::${this.#entity}::fetch`,
      url: Routes[`protocol_${this.#entity}_path`](),
      toClientAdapter (data) {
        return { nodes: snakeToCamel(data) }
      },
    }).buildQuery()
  }

  delete (nodeIds = []) {
    return new MRequestWrapper({
      type: 'delete',
      location: `MProtocolEntityPresenter::${this.#entity}::destroy`,
      url: Routes.protocol_entities_path(),
      data: { deleted_ids: nodeIds },
    }).buildQuery()
  }

  add (parentId, folder, title) {
    return new MRequestWrapper({
      type: 'post',
      location: `MProtocolEntityPresenter::${this.#entity}::create`,
      url: Routes.protocol_entities_path(),
      data: { parent_id: parentId, folder, title },
    }).buildQuery()
      .then((result) => {
        if (result.errors) { return result.errors }

        return ({ node: snakeToCamel(result) })
      })
  }

  edit (nodeId, title) {
    return new MRequestWrapper({
      type: 'patch',
      location: `MProtocolEntityPresenter::${this.#entity}::update`,
      url: Routes.protocol_entities_path(),
      data: { id: nodeId, title },
    }).buildQuery()
  }
}
