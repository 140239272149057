import { createVueApp } from '@/vue_components/create_vue_app'
import AboutModal from '@/vue_apps/AboutModal/AboutModal'
import { form } from '@/vue_apps/AboutModal/form/form'

const createModal = () => {
  const el = '#vue_about_modal'

  const aboutModal = createVueApp({
    el,
    name: 'AboutModalRoot',
    render: (h) => h(AboutModal, {
      props: {
        onlyIcon: gon.page.controller === 'users/sessions' && gon.page.action === 'new',
        form,
      },
    }),
  })

  // временный костыль, пока slim навбар
  $('#navbar-info').on('click', function () {
    aboutModal.$children[0]?.open()
  })
}

$document.on('turbolinks:load', () => { setTimeout(createModal) })
