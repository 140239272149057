import { FlatDepthTree } from '@/vue_present/Reuse/CatalogTree/store/forest/FlatDepthTree/FlatDepthTree'

export class FlatDepthTreeApi extends FlatDepthTree {
  /** @private
   * @type {IFlatDepthTreeApiPresenter}
   * */
  _presenter

  constructor (presenter) {
    super()
    this._presenter = presenter
  }

  async fetchTree () {
    const { errors, nodes } = await this._presenter.fetch()
    if (errors) { return }

    this._buildTree(nodes)
  }

  async deleteNode (nodeIds) {
    const notRemovable = nodeIds.filter((nodeId) => !this._treeMap[nodeId].isLeaf && this._treeMap[nodeId].children.length)
    if (notRemovable.length) {
      return Utils.reportWarning('FlatDepthTreeApi:deleteNode', t('can_not_delete_with_nested_elements'))()
    }

    const { errors } = await this._presenter.delete(nodeIds)
    if (errors) { return }

    nodeIds.forEach((nodeId) => {
      this._treeMap[nodeId].select(false)
      this._treeMap[nodeId].deleteNode()
    })
  }

  async addNode (parentId, folder, title) {
    const { errors, node } = await this._presenter.add(parentId, folder, title)
    if (errors) { return }
    this._treeMap[node.id] = this._treeMap[parentId].addNode(node)
  }

  async editNode (nodeId, title) {
    const { errors } = await this._presenter.edit(nodeId, title)
    if (errors) { return }
    this._treeMap[nodeId].renameNode(title)
  }
}
