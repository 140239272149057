import { EntryPackPresenter } from '@/vue_apps/catalogs_root/EntryPacks/api/EntryPackPresenter'

/** @implements {IEntryPackEntry} */
export class EntryPackEntry {
  amount = 1

  /** @type {number|null} */
  entryTypeId = null

  price = 0

  sum = 0

  title = ''

  /** @type {number|null} */
  kind = null

  /** @type {number|null} */
  entryPackEntryId = null

  destroyed = false

  /* @type {{ id: number, title: string }[]} */
  allowedClinics = []

  /**  @param {IEntryPackEntryFull} entryType */
  constructor (entryType) {
    this.entryPackEntryId = entryType.entryPackEntryId
    this.entryTypeId = entryType.id
    this.title = entryType.title
    this.price = Utils.toMoney(entryType.price)
    this.amount = entryType.amount || 1
    this.sum = Utils.toMoney(entryType.price)
    this.kind = entryType.kind

    this.setAllowedClinics(entryType.allowedClinics)
  }

  setAmount (amount = 1) {
    this.amount = amount
    this.sum = Utils.toMoney(this.price * amount)
  }

  setAllowedClinics (allowedClinics = []) {
    this.allowedClinics = allowedClinics
  }

  destroy () {
    this.destroyed = true
  }

  restore () {
    this.destroyed = false
  }

  async fetchAllowedClinics () {
    const allowedClinics = await new EntryPackPresenter().fetchAllowedClinics(this.entryTypeId)
    this.setAllowedClinics(allowedClinics)
  }
}
