<template>
  <el-card
    class="m-card"
    shadow="never"
  >
    <slot />
  </el-card>
</template>

<script>
export default {
  name: 'MCard',
}
</script>
