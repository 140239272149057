import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { deliveryListAdapter } from '@/_api/Deliveries/deliveryListAdapter'

export class MClientNotificationPresenter extends MPresenterBase {
  constructor (clientId) {
    super({
      routes: { one () {}, all () {}, list: () => Routes.notifications_list_client_path(clientId) },
      entity: 'message',
      location: 'MClientNotificationPresenter',
    })
  }

  /**
   * @param {TMessageToServer} data
   * @param config
   * @returns {Promise<TMessagesList>}
   */
  list (data, config = {}) {
    const finalData = deliveryListAdapter.toServer(data)

    return super.list(finalData, config)
      .then((data) => Promise.resolve(deliveryListAdapter.toClient(data)))
  }
}
