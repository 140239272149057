<template>
  <div class="finance-module">
    <m-panel
      class="finance-module__left-panel-menu"
      m-panel-class=""
      :title="t('finance.title')"
      :is-collapsed="true"
      is-parent-control
      icon="finance"
    >
      <div class="finance-module__menu">
        <m-menu
          :menu-categories="navRoutes"
          :is-collapsed="isMenuCollapsed"
          router
        />
      </div>
    </m-panel>

    <router-view :class="$route.name" />
  </div>
</template>

<script>
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import { navRoutes } from '@/vue_apps/FinanceModule/FinanceModuleIndex/router/navRoutes'
import MMenu from '@/vue_present/_base/MMenu/MMenu.vue'

export default {
  name: 'FinanceModule',
  components: { MMenu, MPanel },

  data () {
    return {
      navRoutes: navRoutes(),
      isMenuCollapsed: true,
    }
  },
}
</script>
