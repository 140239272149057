const beginningOfDay = () => moment().hour(0).minutes(0).seconds(0)
const endOfDay = () => moment().hour(23).minutes(59).seconds(59)
/**
 * @returns {{
 *   __cacheKey: string,
 *   deliveryType: TMessageDeliveryType,
 *   period: [string, string] | [Date, Date],
 *   messageType: string,
 *   errorCode: string,
 *   status: TMessageStatus,
 * }}
 */
export const getDefaultMessagesFilters = () => {
  return {
    __cacheKey: 'communication/messages',
    deliveryType: null,
    period: [beginningOfDay().toDate(), endOfDay().toDate()],
    status: null,
    messageType: null,
    error: null,
  }
}
