<template>
  <div class="checkups-module">
    <div
      v-if="isMainPage"
      class="checkups-module__aside"
    >
      <m-panel
        class="checkups-module__left-panel-menu"
        :title="t('checkups.navbar.title')"
        collapsable
        is-parent-control
        icon="checkups"
        :is-collapsed.sync="isMenuCollapsed"
      >
        <div class="checkups-module__menu">
          <!-- Меню -->
          <m-menu
            :menu-categories="navRoutes"
            :is-collapsed="isMenuCollapsed"
            router
          />
        </div>
      </m-panel>
    </div>

    <router-view
      class="checkups-module__content"
      :class="$route.name"
    />
  </div>
</template>

<script>
import { navRoutes } from '@/vue_apps/CheckupsModule/router/navRoutes'
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import { CHECKUPS_ROUTE_NAMES } from '@/vue_apps/CheckupsModule/router/routeNames'
import MMenu from '@/vue_present/_base/MMenu/MMenu.vue'

export default {
  name: 'CheckupsModule',
  components: { MMenu, MPanel },

  data () {
    return {
      navRoutes: navRoutes(),
      isMenuCollapsed: true,
    }
  },

  computed: {
    isMainPage () {
      return this.$route.name === CHECKUPS_ROUTE_NAMES.PERIODIC_CHECKUPS ||
          this.$route.name === CHECKUPS_ROUTE_NAMES.PRIOR_CHECKUPS
    },
  },
}
</script>
