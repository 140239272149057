import { createVueApp } from '@/vue_components/create_vue_app'
import OldDocumentFooterApp from '@/vue_apps/Documents/OldDocumentFooter/OldDocumentFooterApp.vue'

let vueApp = null
export const createOldDocumentFooterApp = (el = '#vue_create_old_document_footer_root') => {
  if (!document.querySelector(el)) { return }
  if (vueApp?.$destroy) { vueApp.$destroy() }

  vueApp = createVueApp({
    el,
    name: 'OldDocumentFooterApp',
    render: (h) => h(OldDocumentFooterApp),
  })
}

$document.on('rez/documents/new rez/documents/edit', () => createOldDocumentFooterApp())
