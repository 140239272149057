
export const CatalogWrapperUIProps = {
  catalogClass: {
    type: String,
    default: null,
  },

  catalogIcon: {
    type: String,
    default: null,
    required: true,
  },

  catalogTitle: {
    type: String,
    default: null,
    required: true,
  },

  listIcon: {
    type: String,
    default: null,
  },

  tableIcon: {
    type: String,
    default: null,
  },

  /**
   * Текст: catalogTitle в единственном числе, Род. падеже
   * @example "лабораторию"
   *
   * Требуется для уточнения того, что создается/изменяется (prop: mode)
   * @example "Создать лабораторию"
   */
  tableTitle: {
    type: String,
    default: null,
    required: true,
  },

  mode: {
    type: String,
    required: true,
  },

  customClassForm: {
    type: String,
    default: null,
  },

  loading: Boolean,

  addItemTooltip: {
    type: String,
    default: null,
  },
}
