import { createVueApp } from '@/vue_components/create_vue_app'
import { createStore } from '@/vue_components/store/store'
import router from '@/vue_components/router/router'
import CheckupsModule from '@/vue_apps/CheckupsModule/CheckupsModule.vue'

let vueApp = null
const createCheckupApp = (el = '#vue_checkups_root') => {
  if (!Services.security.canViewCheckupsCompany) { return }

  if (vueApp?.destroy) { vueApp.destroy() }

  vueApp = createVueApp({
    el,
    name: 'CheckupsModuleApp',
    store: createStore(),
    router,
    render: (h) => h(CheckupsModule),
  })
}

$document.on('rez/checkup', () => createCheckupApp())
