<template>
  <div class="flex flex-column align-start gap-indent-mid">
    <slot />

    <m-icon
      icon="warning"
      color="warning"
      class="network-module__caution-icon mt-indent"
    />

    <div
      class="network-module__caution-text"
      v-html="t('network_vue.prompt')"
    />
  </div>
</template>

<script>
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'

export default {
  name: 'ModuleDisabledPage',
  components: { MIcon },
}
</script>
