import {
  createDefaultAppointmentSource,
} from '@/vue_apps/catalogs_root/appointment_sources/const/createDefaultAppointmentSource'

const validationText = t('errors.filled?')
const validationExistsText = t(
  'errors.pre_existing_title?',
  { item: t('utils_report_error.entities.appointment_source') }
)

export const AppointmentSourcesValidationsMixin = {
  data () {
    return {
      tempActiveItem: createDefaultAppointmentSource(),

      validationMessages: {
        title: [],
        default_attraction_source: [],
      },

      watchers: [],
    }
  },

  methods: {
    resetValidations () {
      this.__resetValidationsFields()
      this.__resetWatchers()
    },

    __resetValidationsFields () {
      Object.keys(this.validationMessages).forEach((key) => {
        this.validationMessages[key] = []
      })
    },

    __resetWatchers () {
      this.watchers.forEach((watcher) => { watcher() })

      this.watchers = Object.keys(this.validationMessages).map((key) => {
        return this.$watch(
          () => this.tempActiveItem[key],
          () => { this.validationMessages[key] = [] }
        )
      })
    },

    __validateTitle () {
      const { id } = this.appointmentSources.find((item) => item.title === this.tempActiveItem.title) || {}
      if (id && this.tempActiveItem.id !== id) {
        this.validationMessages.title = [validationExistsText]
      }

      if (!this.tempActiveItem.title) {
        this.validationMessages.title = [validationText]
      }

      return this.validationMessages.title.length
    },

    __validateAttractionSource () {
      if (!this.tempActiveItem.default_attraction_source.id) {
        this.validationMessages.default_attraction_source = [validationText]
      }

      return this.validationMessages.default_attraction_source.length
    },

    hasErrors () {
      return [
        this.__validateTitle(),
        this.__validateAttractionSource(),
      ].some(Boolean)
    },
  },
}
