<template>
  <configurations-base>
    <template #settingsList>
      <sms-configurations-menu
        @openSettingsPage="openSettingsPage"
      />
    </template>

    <template #settingPage>
      <router-view
        :whatsapp-enabled="isWhatsappModuleEnabled"
        :notifications-module-enabled="notificationsModuleEnabled"
      />
    </template>
  </configurations-base>
</template>

<script>
import ConfigurationsBase
  from '@/vue_apps/app_configuration/messages/_messages_configurations_base/ConfigurationsBase.vue'
import SmsConfigurationsMenu
  from '@/vue_apps/app_configuration/messages/_messages_configurations_base/SmsConfigurationsMenu.vue'

export default {
  name: 'MessagesConfiguration',
  components: {
    SmsConfigurationsMenu,
    ConfigurationsBase,
  },

  data () {
    return {
      showForm: false,
      panelTitle: '',
    }
  },

  computed: {
    isWhatsappModuleEnabled () {
      return gon.application.whatsapp_module_enabled
    },

    notificationsModuleEnabled () {
      return gon.application.sms_module_enabled || gon.application.whatsapp_module_enabled
    },
  },

  methods: {
    openSettingsPage (selectedPage) {
      this.showForm = true

      this.$router.push({ name: selectedPage.name })
    },
  },
}
</script>
