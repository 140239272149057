import { MNetworkPresenter } from '@/_api/MNetwork/MNetworkPresenter'
import { WhiteListRecordLogic } from '@/vue_apps/app_configuration/network/entities/WhiteListRecordLogic'
import { IWhiteListRecord } from '@/vue_apps/app_configuration/network/interfaces/IWhiteListRecord'
import { IWhiteListRequestError } from '@/vue_apps/app_configuration/network/interfaces/IWhiteListRequestError'

export class WhiteListRecordApi extends WhiteListRecordLogic {
  private presenter = new MNetworkPresenter()

  add (): Promise<IWhiteListRecord | IWhiteListRequestError> {
    return this.presenter.submit(this.getAttributes())
  }

  edit (): Promise<undefined | IWhiteListRequestError> {
    return this.presenter.submit(this.getAttributes())
  }
}
