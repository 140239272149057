import { WhiteListRecordModel } from '@/vue_apps/app_configuration/network/entities/WhiteListRecordModel'
import { IWhiteListRecord } from '@/vue_apps/app_configuration/network/interfaces/IWhiteListRecord'
import { DEFAULT_IP } from '@/vue_apps/app_configuration/network/const/const'

export class WhiteListRecordLogic extends WhiteListRecordModel {
  constructor (data?: IWhiteListRecord) {
    super()
    this._fillProps(data)
  }

  reset (): void {
    this.setValue('ipAddress', DEFAULT_IP)
    this.setValue('comment', '')
  }
}
