// Название не по ГОСТу, но и тут не совсем презентер, а заглушка

import {
  FLASH_CALL_DELIVERY_STATUSES,
  MESSAGE_DELIVERY_STATUS_ICONS,
  MESSAGE_DELIVERY_STATUSES,
  MESSAGE_DELIVERY_TYPE,
  MESSAGE_ERRORS_COMMON,
  MESSAGE_ERRORS_FLASH_CALL,
  MESSAGE_ERRORS_SMS,
  MESSAGE_ERRORS_WHATSAPP,
  MESSAGE_TYPES,
  SMS_DELIVERY_STATUSES,
} from '@/vue_apps/CommunicationModule/Messages/const/const'

const sortFn = (a, b) => a.title > b.title ? 1 : -1

export class MMessagesFiltersPresenter {
  fetchDeliveryTypes () {
    return Promise.resolve([
      {
        id: MESSAGE_DELIVERY_TYPE.SMS,
        title: t('whatsapp.sms'),
        color: 'primary',
        icon: 'sms',
      },
      {
        id: MESSAGE_DELIVERY_TYPE.WHATSAPP,
        title: t('whatsapp.whatsapp'),
        useBrand: true,
        color: 'success',
        icon: 'whatsapp',
      },
      {
        id: MESSAGE_DELIVERY_TYPE.FLASH_CALL,
        title: t('whatsapp.flashCall'),
        useBrand: true,
        color: 'primary',
        icon: 'flash_call',
      },
    ])
  }

  fetchStatuses (deliveryType = null) {
    const deliveryTypeStatuses = deliveryType === MESSAGE_DELIVERY_TYPE.SMS
      ? SMS_DELIVERY_STATUSES
      : deliveryType === MESSAGE_DELIVERY_TYPE.FLASH_CALL
        ? FLASH_CALL_DELIVERY_STATUSES
        : MESSAGE_DELIVERY_STATUSES

    return Promise.resolve(
      Object.values(deliveryTypeStatuses)
        .map((key) => ({
          id: key.status,
          title: t(key.status, { scope: 'enums.delivery.status' }),
          ...MESSAGE_DELIVERY_STATUS_ICONS[key.status],
        }))
    )
  }

  fetchTypes () {
    return Promise.resolve(
      Object.values(MESSAGE_TYPES)
        .map((key) => ({
          id: key,
          title: t(key, { scope: 'enums.message.message_type' }),
        }))
        .sort(sortFn)
    )
  }

  /**
   * Тексты тут: config/locales/ru/modules/messages/enums.ru.yml
   * @returns {Promise<{id: string, title: string, type: TMessageDeliveryType}[]>}
   */
  fetchErrors () {
    const common = Object.values(MESSAGE_ERRORS_COMMON)
      .map((value) => ({
        id: value,
        title: t(value, { scope: 'enums.delivery.error_code' }),
      }))

    const sms = Object.values(MESSAGE_ERRORS_SMS)
      .map((value) => ({
        id: value,
        title: t(value, { scope: 'enums.delivery.error_code' }),
        type: MESSAGE_DELIVERY_TYPE.SMS,
      }))

    const whatsapp = Object.values(MESSAGE_ERRORS_WHATSAPP)
      .map((value) => ({
        id: value,
        title: t(value, { scope: 'enums.delivery.error_code' }),
        type: MESSAGE_DELIVERY_TYPE.WHATSAPP,
      }))

    const flashCall = Object.values(MESSAGE_ERRORS_FLASH_CALL)
      .map((value) => ({
        id: value,
        title: t(value, { scope: 'enums.delivery.error_code' }),
        type: MESSAGE_DELIVERY_TYPE.FLASH_CALL,
      }))

    return Promise.resolve(
      [
        ...common,
        ...sms,
        ...whatsapp,
        ...flashCall,
      ]
        .sort(sortFn)
    )
  }
}
