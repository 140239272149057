import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_LIMIT,
  DEFAULT_TOTAL_ITEMS,
  DEFAULT_TOTAL_PAGES,
} from '@/vue_components/store/modules/filters_base'

export const PaginationMixin = {
  data () {
    return {
      limit: DEFAULT_LIMIT,
      totalPages: DEFAULT_TOTAL_PAGES,
      totalItems: DEFAULT_TOTAL_ITEMS,
      currentPage: DEFAULT_CURRENT_PAGE,
    }
  },

  computed: {
    offset: {
      get () {
        return (this.currentPage - 1) * this.limit
      },
      set (value) {
        this.setCurrentPage(Math.floor(value / this.limit) + 1)
      },
    },

    pagination () {
      return {
        offset: this.offset,
        limit: this.limit,
      }
    },
  },

  methods: {
    /**
     * @param {Number} value
     */
    setLimit (value = DEFAULT_LIMIT) {
      this.limit = value
      this.setCurrentPage(DEFAULT_CURRENT_PAGE)
    },

    /**
     * @param {Number} value
     */
    setTotalPages (value) {
      this.totalPages = value
    },

    /**
     * @param {Number} totalPages
     * @param {Number} totalItems
     */
    setTotals (totalPages, totalItems) {
      this.totalPages = totalPages
      this.totalItems = totalItems
    },

    /**
     * @param {Number} page
     */
    setCurrentPage (page) {
      this.currentPage = page
    },

    /**
     * @param {Number} totalPages
     * @param {Number} limit
     */
    resetPagination (totalPages, limit = DEFAULT_LIMIT) {
      this.setLimit(limit)
      this.setTotalPages(totalPages)
    },
  },
}
