import AttractionSources from '@/vue_apps/catalogs_root/attraction_sources/AttractionSources.vue'
import { createVueApp } from '@/vue_components/create_vue_app'

$document.on('rez/attraction_sources', () => {
  const el = '#vue_attraction_sources'

  createVueApp({
    el,
    name: 'AttractionSourcesCatalogRoot',
    render: (h) => h(AttractionSources),
  })
})
