<template>
  <m-input
    ref="input"
    class="octet-input center"
    :value="value"
    :clearable="false"
    :m-fixed-height="false"
    @input="setValue"
    @keydown.native="handleKeydown"
  />
</template>

<script>
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import { getValidOctetValue } from '@/vue_apps/app_configuration/network/const/getValidOctetValue'
import { MAX_OCTET_LENGTH } from '@/vue_apps/app_configuration/network/const/const'

export default {
  name: 'OctetInput',
  components: { MInput },

  props: {
    value: {
      type: Number,
      default: 0,
    },
  },

  emits: [
    'input',
    'onFull',
    'onDelWhenZero',
    'onSpaceKey',
  ],

  methods: {
    setValue (val) {
      const validVal = getValidOctetValue(val)

      if (`${validVal}`.length === MAX_OCTET_LENGTH) {
        this.$emit('onFull')
      }

      this.$emit('input', validVal)
    },

    handleKeydown ({ code }) {
      if (!this.value && (code === 'Delete' || code === 'Backspace')) {
        return this.$emit('onDelWhenZero')
      }

      if (code === 'Space') {
        return this.$emit('onSpaceKey')
      }
    },

    focus () {
      this.$refs.input.$refs.input.focus()
    },

    select () {
      this.$refs.input.$refs.input.select()
    },
  },
}
</script>
