import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { tScoped } from '@/helpers/i18nHelpers'

const ts = tScoped('network_vue.table')

export const getWhiteListSchema = () =>
  new MSiSchema()
    .addSearch()
    .addPagination()
    .addTableSchema(
      new MTableSchema()
        .addCheckboxes()
        .addDeleteIcon()
        .addHeaders({
          ipAddress: new MTableHeader(ts('ip_address')).addFixedWidth('140px'),
          active: new MTableHeader(ts('active')).addFixedWidth('120px'),
          comment: new MTableHeader(ts('comment')),
        })
    )
