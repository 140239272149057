import {
  createDefaultAttractionSource,
} from '@/vue_apps/catalogs_root/attraction_sources/const/createDefaultAttractionSource'

const validationText = t('errors.filled?').toLowerCase()
const validationExistsText = t('errors.unique?').toLowerCase()

export const AttractionSourceValidationMixin = {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  data () {
    return {
      tempActiveItem: createDefaultAttractionSource(),
      validationMessages: {
        title: [],
        phone: [],
      },

      watchers: [],
    }
  },

  methods: {
    resetValidations () {
      this.__resetValidationsFields()
      this.__resetWatchers()
    },

    __resetValidationsFields () {
      Object.keys(this.validationMessages).forEach((key) => {
        this.validationMessages[key] = []
      })
    },

    __resetWatchers () {
      this.watchers.forEach((watcher) => { watcher() })

      this.watchers = Object.keys(this.validationMessages).map((key) => {
        return this.$watch(
          () => this.tempActiveItem[key],
          () => { this.validationMessages[key] = [] }
        )
      })
    },

    __validateTitle () {
      const { id } = this.attractionSources.find((item) => item.title === this.tempActiveItem.title) || {}
      if (id && this.tempActiveItem.id !== id) {
        this.validationMessages.title = [validationExistsText]
      }

      if (!this.tempActiveItem.title) {
        this.validationMessages.title = [validationText]
      }

      return this.validationMessages.title.length
    },

    hasErrors () {
      return Boolean(this.__validateTitle())
    },
  },
}
