<template>
  <catalog-wrapper
    :catalog-icon="catalogIcon"
    :catalog-title="catalogTitle"
    :list-icon="listIcon"
    :add-item-tooltip="t('add_attraction_source')"
    :items="items"
    :table-schema="tableSchema"
    :table-title="tableTitle"
    :active-item="activeItem"
    :mode="mode"
    :loading="loading"
    :can-manage="canManage"
    :current-page.sync="currentPage"
    :total-pages="totalPages"
    :is-system-fn="isSystemFn"
    @onItemClick="onItemClick"
    @onAddItem="onResetWithMode"
    @close="onResetWithMode(MODES.SHOW)"
  >
    <template #control-search>
      <span />
    </template>

    <template #form>
      <attraction-sources-form
        :can-manage="canManage"
        :active-item="activeItem"
        :attraction-sources="items"
        :mode="mode"
        @successSubmit="onSuccessSubmit"
        @successDestroy="onSuccessDestroy"
      />
    </template>
  </catalog-wrapper>
</template>

<script>
import CatalogWrapper from '@/vue_apps/catalogs_root/_catalog_wrapper/CatalogWrapper.vue'
import { PaginationMixin } from '@/vue_present/mixins/PaginationMixin'
import {
  attractionSourcesTableSchema,
  SYSTEM_ATTRACTION_SOURCE,
} from '@/vue_apps/catalogs_root/attraction_sources/const/const'
import { MODES } from '@/vue_apps/catalogs_root/_catalog_base/const/const'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { attractionSourcesPresenter } from '@/api_entities/catalogs/attraction_sources/attraction_sources_presenter'
import AttractionSourcesForm from '@/vue_apps/catalogs_root/attraction_sources/components/AttractionSourcesForm.vue'
import { addItem, destroyItem, updateItem } from '@/vue_apps/catalogs_root/attraction_sources/logic/itemsActions'

export default {
  name: 'AttractionSources',
  components: { AttractionSourcesForm, CatalogWrapper },
  mixins: [PaginationMixin, SpinnerHolder],

  data () {
    return {
      loading: false,
      mode: MODES.SHOW,
      items: [],
      activeItem: null,
      MODES,
    }
  },

  computed: {
    catalogIcon () {
      return 'attractionSources'
    },

    listIcon () {
      return 'fad fa-compress-arrows-alt'
    },

    catalogTitle () {
      return t('attraction_sources')
    },

    tableSchema () {
      return attractionSourcesTableSchema
    },

    tableTitle () {
      return t('source')
    },

    /**
     * @returns {boolean}
     */
    canManage () {
      return this.$security.canManageAttractionSource
    },

    sortParams () {
      return {
        ...this.pagination,
      }
    },

    isSystemFn () {
      return (item) => [SYSTEM_ATTRACTION_SOURCE].includes(item.system)
    },
  },

  watch: {
    sortParams (to) {
      this.fetchItems(to)
    },
  },

  created () {
    this.fetchItems()
  },

  methods: {
    async fetchItems (sortParams = this.sortParams) {
      const { data, total_pages: totalPages } =
          await this.withSpinner(attractionSourcesPresenter.fetchAll(sortParams))
      this.items = data
      this.setTotalPages(totalPages)
    },

    onItemClick (item) {
      this.activeItem = item
      this.setMode(MODES.EDIT)
    },

    onResetWithMode (mode = MODES.CREATE) {
      this.activeItem = undefined
      this.setMode(mode)
    },

    setMode (mode) {
      this.mode = mode
    },

    onSuccessSubmit (item) {
      if (item.isNew) {
        this.items = addItem(this.items, item)
        this.onResetWithMode()

        return
      }

      updateItem(this.items, item)
    },

    onSuccessDestroy (itemId) {
      this.items = destroyItem(this.items, itemId)
      this.onResetWithMode(MODES.SHOW)
    },
  },
}
</script>
