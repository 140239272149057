import { periodicCheckupsRoute, priorCheckupsRoute } from '@/vue_apps/CheckupsModule/router/checkupsModuleRoutes'

export const navRoutes = () => Object.freeze([
  {
    items: [periodicCheckupsRoute],
    vertical: true,
  },
  {
    items: [priorCheckupsRoute],
    vertical: true,
  },
])
