<template>
  <div
    class="entry-pack-entry-wrapper flex flex-column"
    :class="{ 'destroyed': data.destroyed }"
  >
    <div class="entry-pack-entry">
      <m-icon
        class="entry-pack-entry__kind"
        :icon="icon"
        color="gray"
      />

      <div
        v-tooltip="data.title"
        class="entry-pack-entry__title cut"
      >
        {{ data.title }}
      </div>

      <span class="entry-pack-entry__price">{{ price }}</span>

      <m-input
        class="entry-pack-entry__amount"
        type="number"
        :value="data.amount"
        :min="1"
        :m-fixed-height="false"
        :precision="0"
        controls
        size="mini"
        :disabled="data.destroyed || 'EntryPacksPack'"
        @input="!data.destroyed && $emit('onAmountChange', $event)"
      />

      <span class="entry-pack-entry__sum">{{ sum }}</span>

      <m-icon
        v-if="data.destroyed"
        v-tooltip="t('restore')"
        class="entry-pack-entry__action"
        icon="send"
        color="primary"
        @click="$emit('onRestore')"
      />
      <m-button-delete
        v-else
        disabled="EntryPacksPack"
        class="entry-pack-entry__action"
        @yes="$emit('onDelete')"
      />
    </div>

    <div
      class="entry-pack-entry-wrapper__allowed-clinics prompt-notice"
      :class="{ 'warning': data.allowedClinics.length }"
    >
      {{ allowedClinics }}
    </div>
  </div>
</template>

<script>
import MButtonDelete from '@/vue_present/_base/buttons/MButtonDelete/MButtonDelete.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'

export default {
  name: 'EntryPacksEntry',
  components: { MIcon, MInput, MButtonDelete },
  props: {
    /** @type {{ EntryPackEntry }} */
    data: { type: Object, required: true },
  },

  emits: [
    'onDelete',
    'onAmountChange',
    'onRestore',
  ],

  computed: {
    icon () {
      return this.$filters.entryTypeIcon(this.data.kind)
    },

    price () {
      return this.$filters.price(this.data.price)
    },

    sum () {
      return this.$filters.price(this.data.sum)
    },

    allowedClinics () {
      const clinics = this.data.allowedClinics

      return clinics.length
        ? t('entryPacks.availableInClinic', { name: clinics.map((c) => c.title).join(', ') })
        : t('entryPacks.availableInAllClinics')
    },
  },
}
</script>
