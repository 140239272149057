<template>
  <div class="network-module">
    <component :is="currentPageComponent">
      <m-button
        v-loading="isToggleProcessOn"
        :text="toggleModuleBtnParams.text"
        :button-style="toggleModuleBtnParams.style"
        :disabled="toggleModuleBtnParams.disabled"
        :icon="toggleModuleBtnParams.icon"
        type="primary"
        class="network-module__toggle-btn"
        @click="toggleModule"
      />
    </component>
  </div>
</template>

<script>
import ModuleDisabledPage from '@/vue_apps/app_configuration/network/pages/ModuleDisabledPage.vue'
import ModuleActivePage from '@/vue_apps/app_configuration/network/pages/ModuleActivePage.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { MNetworkPresenter } from '@/_api/MNetwork/MNetworkPresenter'

const NOT_ALLOWED_DISABLED_TOGGLE_BTN_CONFIG = Object.freeze({
  disabled: true,
  tooltip: t('whitelist.demomed_ban'),
})

export default {
  name: 'NetworkModule',
  components: {
    ModuleDisabledPage,
    ModuleActivePage,
    MButton,
  },

  props: {
    moduleIsEnabledDefault: Boolean,
    isModuleAllowed: Boolean,
  },

  data () {
    return {
      isModuleEnabled: this.moduleIsEnabledDefault,
      isToggleProcessOn: false,
    }
  },

  computed: {
    currentPageComponent () {
      return this.isModuleEnabled
        ? 'ModuleActivePage'
        : 'ModuleDisabledPage'
    },

    toggleModuleBtnParams () {
      return {
        style: this.isModuleEnabled
          ? 'round'
          : 'fill',

        icon: this.isModuleEnabled
          ? 'switchOn'
          : 'switchOff',

        disabled: this.isModuleAllowed
          ? false
          : NOT_ALLOWED_DISABLED_TOGGLE_BTN_CONFIG,

        text: t(`network_vue.module_is_enabled_${this.isModuleEnabled}`),
      }
    },

  },

  methods: {
    async toggleModule () {
      this.isToggleProcessOn = true

      const res = await new MNetworkPresenter().toggleModule(!this.isModuleEnabled)

      this.isToggleProcessOn = false

      if (res?.errors) { return }

      this.isModuleEnabled = res?.whiteListLoginEnabled ?? !this.isModuleEnabled
    },
  },
}
</script>
