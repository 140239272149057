<template>
  <catalogs-base
    :catalog-icon="catalogIcon"
    :catalog-title="catalogTitle"
    :table-icon="tableIcon || catalogIcon"
    :table-title="tableTitleText"
    :can-manage="canManage"
    :custom-class-form="customClass"
    :mode="mode"
    @close="$emit('close')"
  >
    <template #primary>
      <si-generator
        v-loading="loading"
        :add-item-tooltip="addItemTooltip"
        :table-schema="tableSchema"
        :primary-icon="catalogIcon"
        :items="items"
        :active-item-id="activeItemId"
        :current-page="currentPage"
        :total-pages="totalPages"
        :can-manage="canManage"
        class="flex-grow-1"
        @update:currentPage="$updateSync('currentPage', $event)"
        @onItemClick="$emit('onItemClick', $event)"
        @onAddItem="$emit('onAddItem')"
      >
        <template #control-search>
          <slot name="control-search" />
        </template>

        <template #table-header>
          <div class="si-field">
            <div class="catalogs_table__title">
              {{ t('title') }}
            </div>
          </div>
        </template>
        <template
          v-for="item in items"
          #tr-icon="{ item }"
        >
          <div
            :key="`id:${item.id}`"
            class="col col__icon"
          >
            <i :class="[listIcon, {grey: isSystemFn(item)}]" />
          </div>
        </template>

        <template #footer-pagination>
          <slot name="footer-pagination" />
        </template>
      </si-generator>
    </template>

    <template #form>
      <slot name="form" />
    </template>
  </catalogs-base>
</template>

<script>
import CatalogsBase from '@/vue_apps/catalogs_root/_catalog_base/CatalogsBase.vue'
import { CatalogWrapperUIProps } from '@/vue_apps/catalogs_root/_catalog_wrapper/props/CatalogWrapperUIProps'
import { MODES } from '@/vue_apps/catalogs_root/_catalog_base/const/const'
import { MODES_ACTIONS } from '@/vue_apps/catalogs_root/_catalog_wrapper/const/const'
import SiGenerator from '@/vue_components/sort_items/SiGenerator/SiGenerator.vue'
import {
  CatalogWrapperPaginationProps,
} from '@/vue_apps/catalogs_root/_catalog_wrapper/props/CatalogWrapperPaginationProps'

/**
 * @deprecated
 */
export default {
  name: 'CatalogWrapper',
  components: { SiGenerator, CatalogsBase },

  props: {
    ...CatalogWrapperUIProps,
    ...CatalogWrapperPaginationProps,

    tableSchema: {
      type: Object,
      required: true,
    },

    items: {
      type: Array,
      default: () => [],
      required: true,
    },

    activeItem: {
      type: Object,
      default: null,
    },

    canManage: Boolean,

    isSystemFn: {
      type: Function,
      default: (item) => Boolean(item && item.system_name),
    },
  },

  emits: [
    'onAddItem',
    'onItemClick',
    'update:currentPage',
    'close',
  ],

  computed: {
    tableTitleText () {
      return `${MODES_ACTIONS[this.mode]} ${this.tableTitle.toLowerCase()}`
    },

    customClass () {
      return this.mode === MODES.CREATE ? 'panel-green' : 'panel-orange'
    },

    activeItemId () {
      return this.activeItem && this.activeItem.id
    },
  },
}
</script>
