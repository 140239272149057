
export const clientMessagesTableSchema = {
  options: { empty: true },
  headers: {
    deliveryTypeIcon: { icon: 'comments', color: 'grey-dark', tooltip: t('sms.deliveryType') },
    statusIcon: { icon: 'allMessageStatuses', color: 'grey-dark', tooltip: t('attributes.status') },
    dateTime: t('attributes.date'),
    text: t('attributes.text'),
    messageType: t('attributes.type'),
  },
}
