import {
  getBranchTemplate,
  getEmailsTemplate,
  getHrefTemplate,
  getLicCountTemplate,
  getPhonesTemplate,
  getSupportHours,
  getVersionTemplate,
} from '@/vue_apps/AboutModal/form/formTemplates'

const appGon = gon.application

export const form = [
  {
    label: t('title'),
    template: getBranchTemplate(appGon.branch),
  },
  {
    label: t('version'),
    template: getVersionTemplate(appGon.version, appGon.build),
  },
  {
    label: t('licence_amount'),
    template: getLicCountTemplate(appGon.lic_count),
  },
  {
    label: t('phone'),
    template: getPhonesTemplate(t('support_modal.phones')),
  },
  {
    label: t('email'),
    template: getEmailsTemplate(t('support_modal.emails')),
  },
  {
    label: t('support_modal.devops_working_hours_text'),
    template: getSupportHours(t('support_modal.devops_working_hours')),
  },
  {
    label: t('web'),
    template: getHrefTemplate({ url: 'https://www.medods.ru', text: t('support_modal.web') }),
  },
  {
    label: t('medods_telegram'),
    template: getHrefTemplate({ url: 'https://t.me/medods_news', text: t('medods_telegram_name') }),
  },
  {
    label: t('learning'),
    template: getHrefTemplate({ url: 'http://bz.medods.ru', text: t('support_modal.bz') }),
  },
  {
    label: t('copyright_holder'),
    template: t('copyright_holder_info'),
  },
]
