import { createVueApp } from '@/vue_components/create_vue_app'
import ReusableCatalogTree from '@/vue_present/Reuse/CatalogTree/ReusableCatalogTree.vue'
import { FlatDepthTreeApi } from '@/vue_present/Reuse/CatalogTree/store/forest/FlatDepthTreeApi'
import { MProtocolEntityPresenter } from '@/_api/catalogs/MProtocolEntity/MProtocolEntityPresenter'
import { createDiseaseApp } from '@/vue_apps/catalogs_root/Diseases'

let vueApp = null

/**
 * @param {IFlatDepthTreeApiPresenter} presenter
 * @param el
 */
const createProtocolEntitiesApp = (presenter, el = '#vue_protocol_entities_root') => {
  if (vueApp?.destroy) { vueApp.destroy() }
  vueApp = createVueApp({
    el,
    name: 'ProtocolEntitiesApp',
    render: (h) => h(ReusableCatalogTree, {
      props: {
        tree: new FlatDepthTreeApi(presenter),
      },
    }),
  })
}

$document.on(
  'rez/protocol_entities/conclusions',
  () => createProtocolEntitiesApp(new MProtocolEntityPresenter('conclusions'))
)

$document.on(
  'rez/protocol_entities/complaints',
  () => createProtocolEntitiesApp(new MProtocolEntityPresenter('complaints'))
)

$document.on(
  'rez/protocol_entities/recommendations',
  () => createProtocolEntitiesApp(new MProtocolEntityPresenter('recommendations'))
)

$document.on(
  'rez/protocol_entities/main',
  () => createDiseaseApp()
)
