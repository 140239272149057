import { navigation } from '@/vue_present/_FrontBook/router/navigation'

/**
 * menu:
 *   category:
 *     item
 *     item
 *     ...
 *   ...
 */
export const menu = Object.freeze([
  { items: [navigation.intro] },

  {
    isCategory: true,
    index: 'base-stuff',
    label: 'Базовые',
    items: [
      navigation.mIcon,
      navigation.mButton,
      navigation.mDatePicker,
      navigation.mTimePicker,
      navigation.mSiGenerator,
    ],
  },

  {
    isCategory: true,
    index: 'form',
    label: 'Форма',
    items: [
      navigation.mInput,
      navigation.mTextarea,
      navigation.mSelect,
      navigation.mCheckbox,
      navigation.mButtonsGroup,
      navigation.mFormShow,
    ],
  },

  {
    isCategory: true,
    index: 'mixins',
    label: 'Миксины',
    items: [
      navigation.disabledMixin,
      navigation.paginationMixin,
      navigation.validationMixins,
    ],
  },
])
