
export const messagesTableSchema = Object.freeze({
  options: { empty: true },
  headers: {
    deliveryTypeIcon: { icon: 'comments', color: 'grey-dark', tooltip: t('sms.deliveryType') },
    statusIcon: { icon: 'allMessageStatuses', color: 'grey-dark', tooltip: t('attributes.status') },
    number: t('attributes.number'),
    dateTime: t('attributes.date'),
    phone: t('attributes.phone'),
    clients: t('attributes.client'),
    text: t('attributes.text'),
    messageType: t('attributes.type'),
    errorText: t('attributes.error'),
  },
})
