import FinanceModule from '@/vue_apps/FinanceModule/FinanceModuleIndex/FinanceModule.vue'
import { createVueApp } from '@/vue_components/create_vue_app'
import router from '@/vue_components/router/router'

let vueApp = null

export const createFinanceModuleVue = (el = '#vue_finance_module_root') => {
  if (vueApp?.destroy) { vueApp.destroy() }

  vueApp = createVueApp({
    el,
    name: 'FinanceModule',
    router,
    render: (h) => h(FinanceModule),
  })
}

$document.on('rez/finance/application', () => {
  createFinanceModuleVue()
})
