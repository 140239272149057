<template>
  <div>
    <popover
      v-tooltip="t('delete')"
      :disabled="disabled || isSystem"
      @yes="onDelete"
    >
      <template slot="reference">
        <i class="fad fa-trash red pointer font-size-17" />
      </template>
    </popover>

    <m-modal
      :visible.sync="modalVisible"
      width="600px"
    >
      <template #header>
        <span>{{ modalTitle }}</span>
      </template>

      <template #body>
        <div>
          <span>{{ modalBodyText }}</span>
          <m-select-lazy-deprecated
            v-model="replacementSource"
            :loading-method="loadingMethod"
            :excluded-items-ids="excludedItemsIds"
            full-width
            @syncItems="onSyncItems"
          />
        </div>
      </template>

      <template #footer-right>
        <button
          class="btn btn-sm btn-with-icon btn-danger"
          :disabled="confirmButtonDisabled"
          @click="onDeleteConfirm"
        >
          <i class="btn-with-icon_icon fad fa-exclamation-circle" />
          <span class="btn-with-icon_text">{{ t('delete') }}</span>
        </button>

        <close-button @close="closeModal" />
      </template>
    </m-modal>
  </div>
</template>

<script>
import Popover from '@/vue_components/common/popover/popover.vue'
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import CloseButton from '@/vue_components/common/buttons/CloseButton.vue'
import { attractionSourcesPresenter } from '@/api_entities/catalogs/attraction_sources/attraction_sources_presenter'
import { SYSTEM_ATTRACTION_SOURCE } from '@/vue_apps/catalogs_root/attraction_sources/const/const'
import MSelectLazyDeprecated from '@/vue_present/_base/MSelectLazyDeprecated/MSelectLazyDeprecated.vue'

export default {
  name: 'AttractionSourcesFormDestroy',
  components: { MSelectLazyDeprecated, CloseButton, MModal, Popover },

  props: {
    canManage: Boolean,
    disabled: Boolean,
    isSystem: Boolean,

    activeItem: {
      type: Object,
      default: () => ({}),
    },
  },

  emits: [
    'onDestroy',
  ],

  data () {
    return {
      replacementSource: null,
      modalVisible: false,
      loadingMethod: attractionSourcesPresenter.fetchAll,
    }
  },

  computed: {
    modalTitle () {
      return t('delete_attraction_source', [this.activeItem.title])
    },

    modalBodyText () {
      return t('choose_replacement_attraction_source')
    },

    confirmButtonDisabled () {
      return !this.replacementSource?.id
    },

    excludedItemsIds () {
      return this.activeItem?.id
        ? [this.activeItem.id]
        : []
    },
  },

  methods: {
    onDelete () {
      this.modalVisible = true
    },

    closeModal () {
      this.modalVisible = false
    },

    onDeleteConfirm () {
      if (!this.replacementSource?.id) { return }
      this.$emit('onDestroy', this.replacementSource.id)
      this.closeModal()
    },

    onSyncItems (items) {
      if (this.replacementSource) { return }
      this.replacementSource = items.find((item) => item.system === SYSTEM_ATTRACTION_SOURCE)
    },
  },
}
</script>
