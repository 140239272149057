
export const appointmentSourcesAdapter = {
  toServer (data) {
    return {
      reception_appointment_source: {
        title: data.title,
        default_attraction_source_id: data.default_attraction_source.id,
      },
    }
  },

  toClient (response) {
    return {
      ...response,
      default_attraction_source: {
        id: response.default_attraction_source_id,
        title: '',
      },
    }
  },
}
