import { MPresenterBase } from '@/_api/_requests/MPresenterBase.js'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification.js'
import { MRequestSuccess } from '@/_api/_requests/MRequestSuccess.js'
import { MRequestError } from '@/_api/_requests/MRequestError.js'
import { tScoped } from '@/helpers/i18nHelpers'

const ts = tScoped('network_vue')

const APP_CONFIGURATION_ID = 1
const MODULE_IS_ENABLED_FIELD = 'white_list_login_enabled'
const DUPLICATE_IP_ADDRESS_ERROR_TEXT = ts('server_errors.duplicate')
const IP_ADDRESS_ERRORS_FIELD_NAME = 'ip_address'

const defaultToServerAdapter = (data) => ({ white_list: data })

const handleDuplicateIpAddressError = () => {
  Notificator.warning(ts('warnings.ip_exists'))
}

const duplicateIpAddressErrorMiddleware = (data) => {
  const ipAddressErrors = data.errors?.[IP_ADDRESS_ERRORS_FIELD_NAME]

  if (ipAddressErrors?.includes(DUPLICATE_IP_ADDRESS_ERROR_TEXT)) {
    handleDuplicateIpAddressError()
  }

  return data
}

export class MNetworkPresenter extends MPresenterBase {
  constructor () {
    super({
      entity: 'ipAddress',
      location: 'MNetworkPresenter',
      routes: {
        list: Routes.list_api_internal_white_lists_path,
        one: Routes.api_internal_white_list_path,
        all: Routes.api_internal_white_lists_path,
      },
    })
  }

  submit (data) {
    return super.submit(data, { toServerAdapter: defaultToServerAdapter })
      .then(duplicateIpAddressErrorMiddleware)
  }

  updateMany (data) {
    const options = {
      url: Routes.batch_update_api_internal_white_lists_path(),
      data: defaultToServerAdapter(data),
    }

    const notification = new MRequestNotification(
      'MNetworkPresenter:updateMany',
      'updateMessage',
      'ipAddresses'
    )

    return this.patch(options).promise
      .then(MRequestSuccess.withNotify(undefined, notification))
      .catch(MRequestError.onRequest(notification))
  }

  destroyMany (data) {
    const toServerAdapter = (ids) => ({ ids })

    const options = {
      url: Routes.batch_destroy_api_internal_white_lists_path(),
      data: toServerAdapter(data),
    }

    const notification = new MRequestNotification(
      'MNetworkPresenter:destroy',
      'destroyMessage',
      'ipAddresses'
    )

    return this.delete(options).promise
      .then(MRequestSuccess.withNotify(undefined, notification))
      .catch(MRequestError.onRequest(notification))
  }

  toggleModule (data) {
    const toServerAdapter = (isEnabled) => ({
      app_configuration: { [MODULE_IS_ENABLED_FIELD]: isEnabled },
    })

    const options = {
      url: Routes.app_configuration_path(APP_CONFIGURATION_ID),
      data: toServerAdapter(data),
    }

    const notification = new MRequestNotification(
      'MNetworkPresenter:toggleModule',
      'toggleMessage',
      'networkModule'
    )

    return this.patch(options).promise
      .catch(MRequestError.onRequest(notification))
  }
}
