<template>
  <div class="flex-form flex-form__35-65">
    <template v-for="(formField, index) in form">
      <div
        :key="`form-label:${index}`"
        class="form-label"
      >
        {{ formField.label }}
      </div>
      <div
        :key="`form-field:${index}`"
        class="form-value"
      >
        <div v-html="formField.template" />
      </div>
    </template>
  </div>
</template>

<script>
import { PropsTypes } from '@/vue_present/_base/PropsTypes'

export default {
  name: 'AboutForm',
  props: {
    form: PropsTypes.Array(),
  },
}
</script>
