<template>
  <div class="ip-address-input flex">
    <octet-input
      ref="octet0"
      class="ip-address-input__octet-input"
      :value="octets[0]"
      @input="onOctetChange(0, $event)"
      @onFull="moveSelectTo('octet1')"
      @onSpaceKey="moveSelectTo('octet1')"
    />

    <div class="ip-address-input__delimeter">
      .
    </div>

    <octet-input
      ref="octet1"
      class="ip-address-input__octet-input"
      :value="octets[1]"
      @input="onOctetChange(1, $event)"
      @onFull="moveSelectTo('octet2')"
      @onSpaceKey="moveSelectTo('octet2')"
      @onDelWhenZero="moveFocusTo('octet0')"
    />

    <div class="ip-address-input__delimeter">
      .
    </div>

    <octet-input
      ref="octet2"
      class="ip-address-input__octet-input"
      :value="octets[2]"
      @input="onOctetChange(2, $event)"
      @onFull="moveSelectTo('octet3')"
      @onSpaceKey="moveSelectTo('octet3')"
      @onDelWhenZero="moveFocusTo('octet1')"
    />

    <div class="ip-address-input__delimeter">
      .
    </div>

    <octet-input
      ref="octet3"
      class="ip-address-input__octet-input"
      :value="octets[3]"
      @input="onOctetChange(3, $event)"
      @onDelWhenZero="moveFocusTo('octet2')"
    />
  </div>
</template>

<script>
import OctetInput from '@/vue_apps/app_configuration/network/components/OctetInput.vue'
import { DEFAULT_IP } from '@/vue_apps/app_configuration/network/const/const'

export default {
  name: 'IpAddressInput',
  components: { OctetInput },

  props: {
    value: {
      type: String,
      default: DEFAULT_IP,
    },
  },

  emits: ['input'],

  computed: {
    octets () {
      return this.value.split('.').map((octet) => +octet)
    },
  },

  methods: {
    onOctetChange (octet, val) {
      this.$emit('input', this.octets.toSpliced(octet, 1, val).join('.'))
    },

    moveFocusTo (targetRef) {
      this.$refs[targetRef].focus()
    },

    moveSelectTo (targetRef) {
      this.$refs[targetRef].select()
    },
  },
}
</script>
