import { MSiSchema } from '@/vue_present/_base/Tables/MSiGenerator/MSiSchema'
import { MTableSchema } from '@/vue_present/_base/Tables/MTableGenerator/MTableSchema'
import { MTableHeader } from '@/vue_present/_base/Tables/MTableGenerator/MTableHeader'

export const getClinicsCatalogsSchema = () => new MSiSchema()
  .addCreateButton()
  .addPagination()
  .addTableSchema(
    new MTableSchema({
      headers: {
        icon: new MTableHeader().addFixedWidth('40px'),
        title: new MTableHeader().addFixedWidth('300px'),
        address: new MTableHeader(),
        status: new MTableHeader().addFixedWidth('120px'),
      },
    })
  )
