<template>
  <div
    v-loading="loading"
    class="appointment-sources-form flex-form"
  >
    <div
      v-if="!isCreateMode"
      class="appointment_source_id"
    >
      <div class="form-label">
        <label for="appointment_source_id">
          {{ t('id') }}
        </label>
      </div>

      <div class="form-value">
        <validation-wrapper
          :errors="[]"
          fixed-height
        >
          <el-input
            id="appointment_source_id"
            :value="tempActiveItem.id"
            name="appointment_source[id]"
            clearable
            disabled
          />
        </validation-wrapper>
      </div>
    </div>

    <div class="form-label">
      <label for="appointment_source_title">
        {{ t('title') }}
      </label>

      <required-mark />
    </div>

    <div class="form-value">
      <validation-wrapper
        :errors="validationMessages.title"
        fixed-height
      >
        <el-input
          id="appointment_source_title"
          v-model="tempActiveItem.title"
          name="appointment_source[title]"
          clearable
          :disabled="disabled || isSystem"
        />
      </validation-wrapper>
    </div>

    <div class="form-label">
      <label for="appointment_default_attraction_source">
        {{ t('attraction_source') }}
      </label>

      <required-mark :text="attractionSourceTooltip" />
    </div>

    <div class="form-value">
      <validation-wrapper
        :errors="validationMessages.default_attraction_source"
        fixed-height
      >
        <m-select-lazy-deprecated
          v-model="tempActiveItem.default_attraction_source"
          :loading-method="loadingMethod"
          :items="attractionSources"
          full-width
          @syncItems="onSyncItems"
        />
      </validation-wrapper>
    </div>

    <div class="appointment-sources-form__footer flex flex-grow-1 justify-content-between">
      <button
        type="button"
        :disabled="disabled"
        class="btn btn-sm btn-with-icon"
        :class="btnClass"
        @click="onSubmit"
      >
        <span class="btn-with-icon_icon fad fa-save" />
        <span>{{ t('save') }} </span>
      </button>

      <span v-if="!isCreateMode&&!isSystem">
        <popover
          v-tooltip="t('delete')"
          class="el-popover__reference-wrapper"
          @yes="onDestroy"
        >
          <template slot="reference">
            <i
              v-if="canManage"
              id="delete-record"
              class="fad fa-trash red pointer"
            />
          </template>
        </popover>
      </span>
    </div>
  </div>
</template>

<script>
import RequiredMark from '@/vue_components/common/required_mark.vue'
import ValidationWrapper from '@/vue_components/common/validation_wrapper.vue'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { cloneDeep } from 'lodash'
import {
  createDefaultAppointmentSource,
} from '@/vue_apps/catalogs_root/appointment_sources/const/createDefaultAppointmentSource'
import {
  AppointmentSourcesValidationsMixin,
} from '@/vue_apps/catalogs_root/appointment_sources/mixins/AppointmentSourcesValidationMixin'
import { enrichSubmitted } from '@/vue_apps/catalogs_root/attraction_sources/logic/enrichSubmited'
import { appointmentSourcesPresenter } from '@/api_entities/catalogs/appointment_sources/appointment_sources_presenter'
import popover from '@/vue_components/common/popover/popover.vue'
import { attractionSourcesPresenter } from '@/api_entities/catalogs/attraction_sources/attraction_sources_presenter'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import { findDefaultAttractionSource } from '@/vue_apps/catalogs_root/appointment_sources/logic/itemsActions'
import MSelectLazyDeprecated from '@/vue_present/_base/MSelectLazyDeprecated/MSelectLazyDeprecated.vue'

export default {
  name: 'AppointmentSourcesForm',
  components: { MSelectLazyDeprecated, ValidationWrapper, RequiredMark, popover },

  mixins: [SpinnerHolder, AppointmentSourcesValidationsMixin],

  props: {
    canManage: PropsTypes.Boolean(),

    activeItem: PropsTypes.Object(),

    appointmentSources: PropsTypes.Array(),

    attractionSources: PropsTypes.Array(),

    isCreateMode: Boolean,
  },

  data () {
    return {
      tempActiveItem: {},
      attractionSourceTooltip: t('attraction_source_tooltip'),
      loadingMethod: attractionSourcesPresenter.fetchAll,
    }
  },

  computed: {
    disabled () {
      return !this.canManage
    },

    btnClass () {
      return this.activeItem && this.activeItem.id
        ? 'btn-warning'
        : 'btn-success'
    },

    isSystem () {
      return Boolean(this.activeItem?.system_name)
    },
  },

  watch: {
    activeItem (to) {
      this.setTempActiveItem(to)
    },
  },

  created () {
    this.setTempActiveItem(this.activeItem)
  },

  methods: {
    createAppointmentSource (appointmentSource) {
      const defaultSource = findDefaultAttractionSource(this.attractionSources)

      return appointmentSource ||
          createDefaultAppointmentSource(defaultSource)
    },

    setTempActiveItem (activeItem) {
      this.tempActiveItem = cloneDeep(this.createAppointmentSource(activeItem))
      this.resetValidations()
    },

    async onSubmit () {
      if (this.hasErrors()) { return }

      const response =
          await this.withSpinner(appointmentSourcesPresenter.submit(cloneDeep(this.tempActiveItem)))

      response.default_attraction_source = this.tempActiveItem.default_attraction_source
      const submittedItem = enrichSubmitted(response, this.tempActiveItem.id)

      if (this.isCreateMode) { this.setTempActiveItem() }

      this.$emit('successSubmit', submittedItem)
    },

    async onDestroy () {
      await this.withSpinner(appointmentSourcesPresenter.destroy(this.tempActiveItem.id))
      this.$emit('successDestroy', this.activeItem.id)
    },

    onSyncItems (attractionSources) {
      if (!this.tempActiveItem.default_attraction_source?.id) {
        this.tempActiveItem.default_attraction_source =
            findDefaultAttractionSource(attractionSources)
      }

      this.$emit('syncItems', attractionSources)
    },
  },
}
</script>
