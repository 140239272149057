import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'

export class ClinicsCatalogListItem extends MListServiceItem {
  /**
   * @param {{
   *   id: number,
   *   title: string,
   *   status: string,
   *   addressAttributes: {
   *     id: number,
   *     index: string,
   *     street: string,
   *     house: string,
   *     building: string,
   *     housing: string,
   *     country: string,
   *     region: string,
   *     area: string,
   *     city: string,
   *     flat: string,
   *     nsiRussianSubjectId: number
   *   }
   * }} data
   */
  constructor (data) {
    super()

    this.id = data.id

    this.icon = new MTableCell()
      .addTooltip(`ID: ${this.id}`)
      .addTypeIcon('clinics')
      .addClasses('grey-dark')

    this.title = new MTableCell(data.title)
      .addTooltip(true)
      .addCut()

    this.address = new MTableCell(vueFilters.address({ address: data.addressAttributes }))
      .addTooltip(true)
      .addCut()

    this.status = new MTableCell(t(data.status, { scope: 'enums.clinic.status' }))
    this.originalStatus = data.status

    this.dataAttributes = {
      'data-status': data.status,
    }
  }
}
