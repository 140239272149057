import { RenderFunctionHelpers } from '@/helpers/RenderFunctionHelpers'

const checkbox = (vm, h, data) => {
  return h(
    'el-checkbox',
    {
      class: 'mr-5 mb-0',
      style: 'top: -2px',
      props: {
        value: Boolean(data.selected),
      },
      nativeOn: {
        click (e) {
          e.stopPropagation()
          e.preventDefault()
          vm.onNodeCheck(data, !data.selected)
        },
      },
    }
  )
}

/**
 * @param vm
 * @param {CreateElement} h
 * @param {{ expanded: boolean }} node
 * @param {{ id: number, title: string, parentId?: number, isLeaf: boolean }} data
 * @param store
 * @returns {VNode}
 */
export const editableCatalogTreeRenderFunction = (vm, h, { node, data, store }) => {
  return h(
    'span',
    { class: 'editable-catalog-tree-node cut' },
    [
      checkbox(vm, h, data),
      h('i', { class: RenderFunctionHelpers.getIconClass(!data.isLeaf, node.expanded) }),
      h(
        'i',
        {
          class: 'fal fa-fw fa-bars font-size-17 node-actions mr-5',
          on: {
            click (e) {
              e.stopPropagation()
              e.preventDefault()
              vm.onNodeClick({ e, data })
            },
          },
        }),

      h('span', {
        class: 'node-content',
        directives: [{ name: 'tooltip', value: { tooltip: data.title, placement: 'top-start' } }],
      }, data.title),
    ]
  )
}
