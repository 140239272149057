<template>
  <div class="catalog-tree">
    <context-popup
      ref="contextPopup"
      class="catalog-tree__context-wrapper"
    >
      <slot name="contextBody">
        <catalog-tree-node-actions
          v-if="lastNode"
          :node="lastNode"
          @onDelete="onNodeDelete"
          @onAdd="onNodeAdd"
          @onEdit="onNodeEdit"
        />
      </slot>
    </context-popup>

    <el-tree
      ref="tree"
      class="catalog-tree__tree flex-grow-1"
      :data="treeData"
      :render-content="renderFunction"
      :props="treeProps"
      :default-expanded-keys="defaultExpandedKeys"
      node-key="id"
      @check="onNodeCheck"
    />

    <div class="catalog-tree__actions">
      <m-button
        template="delete"
        :disabled="massDeleteDisabled"
        @click="onMassDelete"
      />
    </div>
  </div>
</template>

<script>
import ContextPopup from '@/vue_present/Reuse/ContextPopup/ContextPopup.vue'
import {
  editableCatalogTreeRenderFunction,
} from '@/vue_present/Reuse/CatalogTree/store/editableCatalogTreeRenderFunction'
import { FlatDepthTreeApi } from '@/vue_present/Reuse/CatalogTree/store/forest/FlatDepthTreeApi'
import CatalogTreeNodeActions from '@/vue_present/Reuse/CatalogTree/CatalogTreeNodeActions.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { MConfirm } from '@/vue_present/_base/MConfirm/MConfirm'

export default {
  name: 'ReusableCatalogTree',
  components: { MButton, CatalogTreeNodeActions, ContextPopup },
  props: {
    tree: FlatDepthTreeApi,
  },

  data () {
    return {
      treeProps: {
        label: 'title',
        children: 'children',
        isLeaf: 'isLeaf',
      },

      treeData: [],
      lastNode: null,
      checkedKeys: [],

      renderFunction: this.getRenderFunction,
    }
  },

  computed: {
    defaultExpandedKeys () {
      return this.treeData.length
        ? [this.treeData[0].id]
        : []
    },

    massDeleteDisabled () {
      return this.checkedKeys.length
        ? false
        : { disabled: true, tooltip: t('protocolEntities.nothingSelected') }
    },
  },

  async created () {
    await this.tree.fetchTree()
    this.syncTree()
  },

  methods: {
    getRenderFunction (h, { node, data, store }) {
      const vm = this

      return editableCatalogTreeRenderFunction(vm, h, { node, data, store })
    },

    syncTree () {
      this.treeData = this.tree.treeData
    },

    closeContext () {
      this.$refs.contextPopup.close()
    },

    onNodeAdd ({ parentNode, isCategory, name }) {
      this.tree.addNode(parentNode.id, isCategory, name)
    },

    onNodeEdit ({ node, name }) {
      this.tree.editNode(node.id, name)
      this.closeContext()
    },

    onNodeClick ({ e, data }) {
      const { pageX, pageY } = e

      this.lastNode = data

      this.$refs.contextPopup.open(pageX, pageY)
    },

    async onNodeDelete (node) {
      this.closeContext()
      await this.tree.deleteNode([node.id])
    },

    /**
     * @param {FlatDepthTreeNode} node
     * @param {boolean} selected
     */
    onNodeCheck (node, selected = false) {
      node.select(selected)
      this.checkedKeys = Array.from(this.tree.treeSelectedNodes)
    },

    async onMassDelete () {
      const result = await MConfirm.error(t('delete_selected_items'))
      if (result !== 'confirm') { return }

      await this.tree.deleteNode(this.checkedKeys)
      this.checkedKeys = this.tree.resetSelection()
    },
  },
}
</script>
