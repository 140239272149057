import ClinicsCatalogApp from '@/vue_apps/catalogs_root/ClinicsCatalog/ClinicsCatalogApp.vue'
import { createVueApp } from '@/vue_components/create_vue_app'

let vueApp = null

export const createClinicsCatalogApp = (el = '#vue_clinics_catalog') => {
  if (!document.querySelector(el)) { return }
  if (vueApp?.destroy) { vueApp.destroy() }

  vueApp = createVueApp({
    el,
    name: 'ClinicsCatalogApp',
    render: (h) => h(ClinicsCatalogApp),
  })
}

$document.on('rez/clinics', () => createClinicsCatalogApp())
