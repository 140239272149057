<template>
  <div class="white-list-page flex gap-indent">
    <div class="white-list-page__left flex flex-column gap-indent-mid">
      <m-card>
        <white-list-editor
          :selected-white-list-records-ids="selectedWhiteListItemsIds"
          :can-submit="canEditorSubmit"
          @onRegisterUpdateData="updateEditorData = $event"
          @onDeletedMany="handleDeletedManyItems"
          @onSubmitted="updateWhiteList"
          @onUndoChanges="whiteList.resetCheckboxes()"
        />
      </m-card>

      <slot />
    </div>

    <m-card class="white-list-page__list-wrapper flex">
      <m-si-generator
        v-loading="whiteList.loading || isItemDeleteProcessOn"
        :si-generator-schema="whiteListSchema"
        :items="whiteList.data"
        :current-page="whiteList.currentPage"
        :page-count="whiteList.totalPages"
        :search-query="whiteList.searchQuery"
        :search-placeholder="t('network_vue.search_placeholder')"
        :search-min-length="1"
        @onItemClick="toggleItemSelectivity"
        @onItemDelete="deleteItem"
        @onSetSelectedItems="setSelectedItems"
        @update:currentPage="whiteList.fetchPage($event)"
        @update:searchQuery="whiteList.search($event)"
      />
    </m-card>
  </div>
</template>

<script>
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import WhiteListEditor from '@/vue_apps/app_configuration/network/components/WhiteListEditor.vue'
import MCard from '@/vue_present/_base/MCard/MCard.vue'
import { getWhiteListSchema } from '@/vue_apps/app_configuration/network/const/getWhiteListSchema'
import { getWhiteList } from '@/vue_apps/app_configuration/network/entities/list/WhiteList'
import { MNetworkPresenter } from '@/_api/MNetwork/MNetworkPresenter'
import { extractId } from '@/_medods_standart_library/msl'

export default {
  name: 'ModuleActivePage',
  components: {
    MSiGenerator,
    WhiteListEditor,
    MCard,
  },

  data () {
    return {
      whiteListSchema: getWhiteListSchema(),
      whiteList: getWhiteList(),
      selectedWhiteListItems: [],
      isItemDeleteProcessOn: false,
      firstlySelectedItemActivity: false,
      updateEditorData () {},
    }
  },

  computed: {
    selectedWhiteListItemsIds () {
      return this.selectedWhiteListItems.map(extractId)
    },

    canEditorSubmit () {
      return !(this.whiteList.loading || this.isItemDeleteProcessOn)
    },
  },

  created () {
    this.updateWhiteList()
  },

  methods: {
    setFirstlySelectedItemActivity (items) {
      this.firstlySelectedItemActivity = items[0]?.rawData.active ?? false
    },

    setSelectedItems (items) {
      if (!this.selectedWhiteListItems.length) {
        this.setFirstlySelectedItemActivity(items)
      }

      this.selectedWhiteListItems = items
      this.updateEditor()
    },

    __extractRawDataFromFirstItem () {
      const firstItem = this.selectedWhiteListItems[0]

      return {
        id: firstItem.id,
        ...firstItem.rawData,
      }
    },

    updateEditor () {
      if (!this.selectedWhiteListItems.length) {
        return this.updateEditorData()
      }

      if (this.selectedWhiteListItems.length === 1) {
        return this.updateEditorData(this.__extractRawDataFromFirstItem())
      }

      this.updateEditorData({ active: this.firstlySelectedItemActivity })
    },

    updateWhiteList () {
      this.whiteList.resetCheckboxes()
      this.whiteList.fetchAll()
    },

    toggleItemSelectivity (item) {
      item.__selected = !item.__selected
    },

    setPrevPage () {
      if (this.whiteList.currentPage !== 1) {
        this.whiteList.setPage(this.whiteList.currentPage - 1)
      }
    },

    checkAllItemsDeletedOnCurrentPage (deletedItemsIds) {
      return this.whiteList.data.every(({ id }) => deletedItemsIds.includes(id))
    },

    setPrevPageIfAllItemsDeletedOnCurrent (deletedItemsIds) {
      if (!Array.isArray(deletedItemsIds)) {
        throw new TypeError('Ожидается массив')
      }

      if (this.checkAllItemsDeletedOnCurrentPage(deletedItemsIds)) {
        this.setPrevPage()
      }
    },

    async handleDeletedManyItems () {
      this.setPrevPageIfAllItemsDeletedOnCurrent(this.selectedWhiteListItemsIds)
      this.updateWhiteList()
    },

    async deleteItem ({ id }) {
      this.isItemDeleteProcessOn = true

      const res = await new MNetworkPresenter().destroy(id)

      this.isItemDeleteProcessOn = false

      if (res?.errors) { return }

      this.setPrevPageIfAllItemsDeletedOnCurrent([id])
      this.updateWhiteList()
    },
  },
}
</script>
