import { DOCUMENT_OWNER_TYPES } from '@/vue_present/Reuse/DocumentTree/store/documentOwnerTypes'

export const getDocumentOwnersFromParams = () => {
  const params = new URLSearchParams(window.location.search)

  return Object.values(DOCUMENT_OWNER_TYPES)
    .reduce((acc, ownerType) => {
      const ownerId = +params.get(`${ownerType}_id`)

      if (!ownerId) { return acc }

      acc.push({
        ownerType,
        ownerId,
      })

      return acc
    }, [])
}
