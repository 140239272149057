import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { pick } from 'lodash'

export class WhiteListItem extends MListServiceItem {
  constructor (data) {
    super()

    this.id = data.id

    this.rawData = { ...pick(data, ['ipAddress', 'active', 'comment']) }

    this.ipAddress = new MTableCell(data.ipAddress)

    this.active = new MTableCell(data.active
      ? t('network_vue.active')
      : t('network_vue.not_active'))

    this.comment = new MTableCell(data.comment)
      .addTooltip(data.comment)
      .addCut()
  }
}
