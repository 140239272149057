<template>
  <catalog-wrapper
    :catalog-icon="catalogIcon"
    :catalog-title="catalogTitle"
    :list-icon="listIcon"
    :add-item-tooltip="t('add_appointment_source')"
    :items="items"
    :table-schema="tableSchema"
    :table-title="tableTitle"
    :active-item="activeItem"
    :mode="mode"
    :loading="loading"
    :can-manage="canManage"
    :current-page.sync="currentPage"
    :total-pages="totalPages"
    :is-system-fn="isSystemFn"
    @close="onResetWithMode(MODES.SHOW)"
    @onItemClick="onItemClick"
    @onAddItem="onResetWithMode"
  >
    <template #control-search>
      <span />
    </template>

    <template #form>
      <appointment-sources-form
        :can-manage="canManage"
        :active-item="activeItem"
        :is-create-mode="isCreateMode"
        :attraction-sources="attractionSources"
        :appointment-sources="items"
        @successSubmit="onSuccessSubmit"
        @successDestroy="onSuccessDestroy"
        @syncItems="onSyncItems"
      />
    </template>
  </catalog-wrapper>
</template>

<script>
import CatalogWrapper from '@/vue_apps/catalogs_root/_catalog_wrapper/CatalogWrapper.vue'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { MODES } from '@/vue_apps/catalogs_root/_catalog_base/const/const'
import { PaginationMixin } from '@/vue_present/mixins/PaginationMixin'
import { appointmentSourcesTableSchema } from '@/vue_apps/catalogs_root/appointment_sources/const/const'
import { appointmentSourcesEndpoint } from '@/api_entities/catalogs/appointment_sources/appointment_sources_endpoint'
import AppointmentSourcesForm from '@/vue_apps/catalogs_root/appointment_sources/AppointmentSourcesForm.vue'
import { addItem, destroyItem, updateItem } from '@/vue_apps/catalogs_root/appointment_sources/logic/itemsActions'
import { cloneDeep } from 'lodash'

export default {
  name: 'AppointmentSources',
  components: { CatalogWrapper, AppointmentSourcesForm },

  mixins: [SpinnerHolder, PaginationMixin],

  data () {
    return {
      loading: false,
      MODES,
      mode: MODES.SHOW,
      items: [],
      activeItem: undefined,
      attractionSources: [],
    }
  },

  computed: {
    catalogIcon () {
      return 'appointmentSources'
    },

    listIcon () {
      return 'fad fa-fw fa-code-branch'
    },

    catalogTitle () {
      return t('appointment_sources')
    },

    tableSchema () {
      return appointmentSourcesTableSchema
    },

    tableTitle () {
      return t('source')
    },

    canManage () {
      return this.$security.canManageReceptionAppointmentSource
    },

    sortParams () {
      return {
        ...this.pagination,
      }
    },

    isCreateMode () {
      return this.mode === MODES.CREATE
    },

    isSystemFn () {
      return (item) => ['medods', 'client_widget'].includes(item.system_name)
    },
  },

  watch: {
    sortParams (to) {
      this.fetchItems(to)
    },
  },

  created () {
    this.fetchItems()
  },

  methods: {
    async fetchItems (sortParams = this.sortParams) {
      const { data, total_pages: totalPages } =
          await this.withSpinner(appointmentSourcesEndpoint.fetchAll(sortParams))
      this.items = data
      this.setTotalPages(totalPages)
    },

    onItemClick (item) {
      this.activeItem = item
      this.setMode(MODES.EDIT)
    },

    onResetWithMode (mode = MODES.CREATE) {
      this.activeItem = undefined
      this.setMode(mode)
    },

    setMode (mode) {
      this.mode = mode
    },

    onSuccessSubmit (item) {
      if (item.isNew) {
        this.items = addItem(this.items, item)
        this.onResetWithMode()

        return
      }

      updateItem(this.items, item)
    },

    onSuccessDestroy (itemId) {
      this.items = destroyItem(this.items, itemId)
      this.onResetWithMode(MODES.SHOW)
    },

    /**
     * Кэширование attractionSources
     * @param {Object[]} attractionSources
     */
    onSyncItems (attractionSources) {
      this.attractionSources = cloneDeep(attractionSources)
    },
  },
}
</script>
