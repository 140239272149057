import Diseases from '@/vue_apps/catalogs_root/Diseases/Diseases.vue'
import { createVueApp } from '@/vue_components/create_vue_app'

let vueApp = null

export const createDiseaseApp = (el = '#vue_diseases_root') => {
  if (vueApp?.destroy) { vueApp.destroy() }

  vueApp = createVueApp({
    el,
    name: 'DiseasesApp',
    render: (h) => h(Diseases),
  })
}
