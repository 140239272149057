<template>
  <div class="clinics-catalog">
    <m-panel
      class="clinics-catalog__list"
      :class="{
        'clinics-catalog__list_show-mode': isShowMode
      }"
      :title="t('clinics')"
      :use-type-by-route="false"
      icon="clinics"
    >
      <m-si-generator
        class="flex-grow-1"
        :items="list.data"
        :page-count="list.totalPages"
        :current-page="list.currentPage"
        :si-generator-schema="schema"
        :add-button-config="addButtonConfig"
        :active-item-id="activeItemId"
        @update:currentPage="list.setPage($event)"
        @onItemClick="onItemClick"
        @onAddItem="onAddItem"
      >
        <template #searchFilters>
          <m-select
            class="ms-auto"
            :value="list.filters.status"
            :items="clinicStatuses"
            :placeholder="t('all_clinics')"
            :m-fixed-height="false"
            use-custom-result="simple"
            @change="list.setFilterValue('status', $event)"
          />
        </template>
      </m-si-generator>
    </m-panel>

    <router-view @onSubmit="onClinicSubmit" />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import { CLINICS_CATALOG_ROUTES } from '@/vue_apps/catalogs_root/ClinicsCatalog/routes/clinicsCatalogRouteNames'
import MSiGenerator from '@/vue_present/_base/Tables/MSiGenerator/MSiGenerator.vue'
import { getClinicsCatalogsSchema } from '@/vue_apps/catalogs_root/ClinicsCatalog/const/getClinicsCatalogsSchema'
import { ClinicsCatalogListItem } from '@/vue_apps/catalogs_root/ClinicsCatalog/entities/ClinicsCatalogListItem'
import MSelect from '@/vue_present/_base/inputs/MSelect/MSelect.vue'
import { getClinicStatuses } from '@/vue_apps/catalogs_root/ClinicsCatalog/const/getClinicStatuses'
import { clinicPresenter } from '@/api_entities/clinics/clinicPresenter'
import { MListServiceApi } from '@/_api/_requests/MListServiceApi/MListServiceApi'

export default defineComponent({
  name: 'ClinicsCatalogApp',
  components: { MSelect, MSiGenerator, MPanel },
  data () {
    return {
      CLINICS_CATALOG_ROUTES,

      list: new MListServiceApi({
        defaultFilters: { status: null },
        src: Routes.catalogs_clinics_list_path(),
        DataItemEntity: ClinicsCatalogListItem,
      }),

      schema: getClinicsCatalogsSchema(),
      clinicStatuses: getClinicStatuses(),
      licensesNumber: 0,
    }
  },

  computed: {
    cantAddClinic () {
      return this.list.data
        .filter((clinic) => clinic.originalStatus === 'active').length >= this.licensesNumber
    },

    canManageAddClinic () {
      return this.cantAddClinic && {
        disabled: true,
        tooltip: t('client_widget.number_of_active_clinics_should_not_exceed_the_number_of_licenses'),
      }
    },

    addButtonConfig () {
      return {
        tooltip: t('add_clinic'),
        icon: 'clinics',
        canManage: this.canManageAddClinic || 'Clinic',
      }
    },

    isShowMode () {
      return this.$route.name === CLINICS_CATALOG_ROUTES.SHOW
    },

    activeItemId () {
      return +this.$route.params.id || null
    },
  },

  created () {
    setTimeout(() => {
      this.list.fetchAll()
      this.fetchLicensesNumber()
    })
  },

  methods: {
    async fetchLicensesNumber () {
      this.licensesNumber = await clinicPresenter.fetchLicensesNumber()
    },

    onItemClick ({ id }) {
      if (id === +this.$route.params.id) { return }
      this.$router.push({ name: CLINICS_CATALOG_ROUTES.EDIT, params: { id } })
    },

    onAddItem () {
      if (this.$route.name === CLINICS_CATALOG_ROUTES.NEW) { return }
      this.$router.push({ name: CLINICS_CATALOG_ROUTES.NEW })
    },

    onClinicSubmit () {
      this.list.setPage()
    },
  },
})
</script>
