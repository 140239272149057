const PERMITTED_USERS = [
  'softs',
]

const FORBIDDEN_HOSTS = [
  'm.medods.ru',
  'd.medods.ru',
  'demomed.medods.ru',
  'demodent.medods.ru',
]

const getHost = () => new URL(location.href).host

export const checkDemoMedBan = (username) =>
  FORBIDDEN_HOSTS.includes(getHost()) &&
  !PERMITTED_USERS.includes(username)
