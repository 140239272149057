<template>
  <catalogs-base
    class="referrals-catalog"
    :catalog-title="t('referrals')"
    catalog-icon="user-md-chat"
    :table-title="tableTitle"
    table-icon="user-md-chat"
    :mode="formMode"
    :can-manage="$security.canManageReferral"
    @close="onCloseForm"
  >
    <!-- Список рефералов -->
    <template #primary>
      <si-generator
        :loading="loading"
        :add-item-tooltip="t('new_referral')"
        :table-schema="referralsTableSchema"
        primary-icon="referral"
        :items="referralsList.data"
        class="referrals-catalog__list flex-grow-1"
        :class="$route.name"
        :total-pages="referralsList.totalPages"
        :current-page.sync="referralsList.currentPage"
        :active-item-id="currentReferralId"
        :can-manage="$security.canManageReferral"
        @onItemClick="onItemClick"
        @onAddItem="onAddItem"
      >
        <template #control-search>
          <m-select-lazy
            :fetch-method="(params) => searchMethod.search(params)"
            :m-fixed-height="false"
            :placeholder="t('search_by_surname')"
            option-label="fullName"
            full-width
            filterable
            use-search-style
            class="mb-indent-mid"
            @change="onItemClick"
          />
        </template>

        <template #beforeTable>
          <div class="flex flex-column">
            <m-checkbox
              v-model="referralsList.withInternalValue"
              :label="t('show_internal_refs')"
            />
            <span class="prompt-notice">
              {{ t('referrals_list_automatically_includes_all_users') }}
            </span>
          </div>
        </template>

        <template
          v-for="referral in referralsList.data"
          #tr-iconOrAvatar="{ item: referral }"
        >
          <div
            :key="`referralId:${referral.id}`"
            class="col col__iconOrAvatar"
          >
            <user-avatar
              v-if="referral.userId"
              :user-id="referral.userId"
              :params="{ version: 'thumb16', tag: referral.avatarTag }"
            />
            <m-icon
              v-else
              icon="referral"
              color=""
            />
          </div>
        </template>

        <template
          v-for="referral in referralsList.data"
          #tr-phone="{ item: referral }"
        >
          <div
            :key="`referralIdPhone:${referral.id}`"
            class="col col__phone"
          >
            <span
              class="phone_number callable"
              @click.stop="() => {}"
            >{{ referral.phone }}</span>
          </div>
        </template>
      </si-generator>
    </template>

    <!-- Форма -->
    <template #form>
      <referral-editor
        :id="currentReferralId"
        @updateList="fetchReferralList"
        @successDestroy="onSuccessDestroy"
      />
    </template>
  </catalogs-base>
</template>

<script>
import CatalogsBase from '@/vue_apps/catalogs_root/_catalog_base/CatalogsBase.vue'
import { referralsTableSchema } from '@/vue_apps/catalogs_root/Referrals/helpers/referralsTableSchema'
import SiGenerator from '@/vue_components/sort_items/SiGenerator/SiGenerator.vue'
import UserAvatar from '@/vue_components/user_avatar.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import MCheckbox from '@/vue_present/_base/inputs/MCheckbox/MCheckbox.vue'
import { REFERRALS_ROUTES_NAME } from '@/vue_apps/catalogs_root/Referrals/routes/routesNames'
import { MODES } from '@/vue_apps/catalogs_root/_catalog_base/const/const'
import ReferralEditor from '@/vue_apps/catalogs_root/Referrals/components/ReferralEditor.vue'
import { ReferralList } from '@/vue_apps/catalogs_root/Referrals/entities/ReferralList'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import { MReferralPresenter } from '@/_api/MReferral/MReferralPresenter'

export default {
  name: 'ReferralsCatalog',
  components: { MSelectLazy, ReferralEditor, MCheckbox, MIcon, UserAvatar, SiGenerator, CatalogsBase },
  mixins: [SpinnerHolder],

  data () {
    return {
      referralsList: new ReferralList(),
      currentReferralId: parseInt(this.$route?.params?.id || 0) || undefined,

      searchMethod: new MReferralPresenter(),
      referralsTableSchema,
      REFERRALS_ROUTES_NAME,
    }
  },

  computed: {
    formMode () {
      if (this.$route.name === REFERRALS_ROUTES_NAME.NEW) { return MODES.CREATE }
      if (this.$route.name === REFERRALS_ROUTES_NAME.EDIT) { return MODES.EDIT }

      return ''
    },

    tableTitle () {
      return this.$route.name === REFERRALS_ROUTES_NAME.NEW
        ? t('new_referral')
        : t('referral')
    },

    referralsListParams () {
      return this.referralsList.params
    },
  },

  watch: {
    referralsListParams () { this.fetchReferralList() },
  },

  created () { this.fetchReferralList() },

  methods: {
    async fetchReferralList () {
      await this.withSpinner(this.referralsList.fetchAll(), undefined, false)
      this.$nextTick(() => {
        Services.telephony.reset()
      })
    },

    jumpToIndex () {
      this.$router.replace({ name: REFERRALS_ROUTES_NAME.INDEX })
    },

    /**
     * @param {TReferralListItem} referral
     */
    onItemClick (referral) {
      this.currentReferralId = referral.id
      this.$router.replace({ name: REFERRALS_ROUTES_NAME.EDIT, params: { id: referral.id } })
    },

    onAddItem () {
      this.currentReferralId = null
      this.$router.replace({ name: REFERRALS_ROUTES_NAME.NEW })
    },

    onSuccessDestroy () {
      this.jumpToIndex()
    },

    onCloseForm () {
      this.currentReferralId = undefined
      this.jumpToIndex()
    },
  },
}
</script>
