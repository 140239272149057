import { createVueApp } from '@/vue_components/create_vue_app'
import MessagesApp from '@/vue_apps/CommunicationModule/Messages/MessagesApp.vue'
import {
  getDefaultClientMessagesFilters,
} from '@/vue_apps/CommunicationModule/ClientMessages/store/getDefaultClientMessagesFilters'
import {
  clientMessagesTableSchema,
} from '@/vue_apps/CommunicationModule/ClientMessages/store/clientMessagesTableSchema'
import { parseInt } from 'lodash'
import { MClientNotificationPresenter } from '@/_api/MClient/MClientNotification/MClientNotificationPresenter'

let vueApp = null
$(document).on('rez/clients/notifications', () => {
  if (vueApp?.destroy) { vueApp.destroy() }

  const clientId = parseInt(gon.page.params.id)

  vueApp = createVueApp({
    el: '#vue_client_messages_app',
    name: 'ClientMessagesAppRoot',
    render: (h) => h(MessagesApp, {
      props: {
        defaultFilters: getDefaultClientMessagesFilters(),
        tableSchema: clientMessagesTableSchema,
        presenter: new MClientNotificationPresenter(clientId),
        useErrorsFilter: false,
        useSearch: false,
        useDatePickerButtons: false,
      },
    }),
  })
})
