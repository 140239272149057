import { ItemBase } from '@/_api/_classes/ItemBase'
import { IWhiteListRecord } from '@/vue_apps/app_configuration/network/interfaces/IWhiteListRecord'
import { DEFAULT_IP } from '@/vue_apps/app_configuration/network/const/const'

export class WhiteListRecordModel extends ItemBase implements IWhiteListRecord {
  _permit = [
    'id',
    'ipAddress',
    'active',
    'comment',
  ]

  id: number = null

  ipAddress: string = DEFAULT_IP

  active: boolean = true

  comment: string = ''
}
