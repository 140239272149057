<template>
  <div class="entry-packs-catalog">
    <m-panel
      type="primary"
      :use-type-by-route="false"
      class="entry-packs-catalog__tree"
      icon="pack"
      :title="t('entryPacks.title')"
      body-css-classes="p-0 pt-indent-small"
    >
      <entry-packs-tree
        ref="entryPacksTree"
        editable
        :current-node-key="currentNodeKey"
        @onNodeDelete="onNodeDelete"
        @onNodeClick="onNodeClick"
      />
    </m-panel>

    <m-panel
      v-if="isEdit"
      class="entry-packs-catalog__edit"
      icon="pack"
      :title="t('entryPacks.editTitle')"
      use-close-button
      @close="onEditPanelClose"
    >
      <entry-packs-editor
        :id="+$route.params.id"
        @onSubmit="onEntryPackSubmit"
        @onDelete="onEntryPackDelete"
      />
    </m-panel>
  </div>
</template>

<script>
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import { ENTRY_PACKS_ROUTES } from '@/vue_apps/catalogs_root/EntryPacks/router/routeNames'
import EntryPacksTree from '@/vue_apps/catalogs_root/EntryPacks/components/EntryPacksTree.vue'
import EntryPacksEditor from '@/vue_apps/catalogs_root/EntryPacks/components/EntryPacksEditor.vue'
import { lazyTreeLeafNodeIdPrefix } from '@/vue_present/Reuse/LazyTree/store/lazyTreeLeafNodeIdPrefix'
import { extractItemId } from '@/vue_present/Reuse/DocumentTree/store/extractItemId'

export default {
  name: 'EntryPacksAppRoot',
  components: { EntryPacksEditor, EntryPacksTree, MPanel },

  computed: {
    isEdit () {
      return this.$route.name === ENTRY_PACKS_ROUTES.EDIT
    },

    currentNodeKey () {
      if (!this.$route.params?.id) { return }

      return `${lazyTreeLeafNodeIdPrefix}${this.$route.params.id}`
    },
  },

  methods: {
    onEditPanelClose () {
      this.$router.push({ name: ENTRY_PACKS_ROUTES.SHOW })
    },

    onNodeDelete () {
      if (this.$route.name === ENTRY_PACKS_ROUTES.SHOW) { return }
      this.$router.replace({ name: ENTRY_PACKS_ROUTES.SHOW })
    },

    onNodeClick (nodeId) {
      if (+nodeId === +this.$route.params?.id) { return }

      this.$router.push({ name: ENTRY_PACKS_ROUTES.EDIT, params: { id: nodeId } })
    },

    /**
     * @param {number} nodeData.id
     * @param {string} nodeData.name
     */
    onEntryPackSubmit (nodeData) {
      this.$refs.entryPacksTree.updateNodeTitle(nodeData)
    },

    /**
     * @param {number} id
     */
    onEntryPackDelete (id) {
      this.onNodeDelete()
      this.$refs.entryPacksTree.removeNode(`${lazyTreeLeafNodeIdPrefix}${extractItemId(id)}`)
    },
  },
}
</script>
