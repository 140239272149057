import { appointmentSourcesEndpoint } from '@/api_entities/catalogs/appointment_sources/appointment_sources_endpoint'
import { reportErrorText, reportSuccessText } from '@/vue_components/egisz/egisz_module/const/egisz_errors/egisz_errors'

export const appointmentSourcesPresenter = {
  fetchAll (sortParams) {
    return appointmentSourcesEndpoint.fetchAll(sortParams)
      .catch(Utils.reportError(
        'responseErrors:fetchAll()',
        reportErrorText('list_message', 'appointment_sources')
      ))
  },

  submit (data) {
    const errorMessage = data.id
      ? 'update_message'
      : 'create_message'

    return appointmentSourcesEndpoint.submit(data)
      .then((response) => {
        Utils.reportSuccess(
          reportSuccessText(errorMessage, 'appointment_source')
        )()

        return new Promise((resolve) => resolve(response))
      })
      .catch((err) => {
        Utils.reportError(
          'responseErrors:onSubmit()',
          reportErrorText(errorMessage, 'appointment_source')
        )(err)
        throw new Error(err)
      })
  },

  destroy (id) {
    return appointmentSourcesEndpoint.destroy(id)
      .then((response) => {
        Utils.reportSuccess(
          reportSuccessText('delete_message', 'appointment_source')
        )()

        return new Promise((resolve) => resolve(response))
      })
      .catch((err) => {
        Utils.reportError(
          'responseErrors:destroy()',
          reportErrorText('delete_message', 'appointment_source')
        )(err)
        throw new Error(err)
      })
  },
}
