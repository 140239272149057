<template>
  <div class="about-modal inline-block">
    <!-- v-if="onlyIcon": Временный костыль пока slim навбар-->
    <div
      v-if="onlyIcon"
      @click="open"
    >
      <i
        v-tooltip="commonTitle"
        class="fad fa-info-circle mr-5"
      />
      <span v-if="!onlyIcon">{{ commonTitle }}</span>
    </div>

    <m-modal
      :visible.sync="modalVisibility"
      width="800px"
    >
      <template #header>
        <span>
          <i class="fad fa-info-circle mr-5" />
          {{ commonTitle }}
        </span>
      </template>

      <template #body>
        <about-form :form="form" />
      </template>
    </m-modal>
  </div>
</template>

<script>
import MModal from '@/vue_present/_base/MModal/MModal'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import AboutForm from '@/vue_apps/AboutModal/components/AboutForm'

export default {
  name: 'AboutModal',
  components: { AboutForm, MModal },

  props: {
    onlyIcon: PropsTypes.Boolean(),
    form: PropsTypes.Array(),
  },

  data () {
    return {
      modalVisibility: false,
    }
  },

  computed: {
    commonTitle () {
      return t('about')
    },
  },

  methods: {
    open () { this.modalVisibility = true },
    close () { this.modalVisibility = false },
  },
}
</script>
