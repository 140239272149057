import { createVueApp } from '@/vue_components/create_vue_app'
import CheckupCatalogs from '@/vue_apps/catalogs_root/CheckupCatalogs/CheckupCatalogs.vue'
import router from '@/vue_components/router/router'
import { createStore } from '@/vue_components/store/store'

let vueApp = null
const createCheckupCatalogsApp = (el = '#vue_checkup_catalogs_root') => {
  if (!document.querySelector(el)) { return }
  if (vueApp?.destroy) { vueApp.destroy() }

  vueApp = createVueApp({
    el,
    name: 'CheckupCatalogsApp',
    router,
    store: createStore(),
    render: (h) => h(CheckupCatalogs),
  })
}

$document.on('rez/checkup_catalogs', () => {
  createCheckupCatalogsApp()
})
