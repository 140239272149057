<template>
  <div class="entry-packs-editor flex flex-column h-100">
    <m-input
      v-model="entryPack.name"
      :label="t('title')"
      required
      validator-name="name"
      disabled="EntryPacksPack"
      @registerValidator="onRegisterValidator"
    />

    <div class="flex gap-indent">
      <m-textarea
        v-model="entryPack.comment"
        :rows="2"
        :label="t('comment')"
        :autosize="{ minRows: 2, maxRows: 2}"
        class="w-100"
        disabled="EntryPacksPack"
      />
    </div>

    <entry-types-search-add
      v-if="$security.canManageEntryPacksPack"
      class="mb-indent"
      :for-current-clinic="false"
      :for-current-user="false"
      :use-entry-packs="false"
      @onEntryTypeAdd="onEntryTypeAdd"
    />

    <div class="entry-packs-container">
      <span
        v-if="!entryPack.entries.items.length"
        class="prompt-notice"
      >
        {{ t('entryPacks.noEntries') }}
      </span>
      <entry-packs-entry-type
        v-for="entryPackEntry in entryPack.entries.items"
        :key="entryPackEntry.entryTypeId"
        :data="entryPackEntry"
        @onAmountChange="entryPackEntry.setAmount($event)"
        @onDelete="entryPackEntry.destroy()"
        @onRestore="entryPackEntry.restore()"
      />
    </div>

    <div class="flex gap-indent-small mb-indent">
      <div class="bold">
        {{ totals }}
      </div>
      <div
        v-if="entryPack.entries.restrictedByClinic"
        class="warning"
      >
        <m-icon
          v-tooltip="t('entryPacks.restrictedByClinic')"
          icon="warning"
          color="warning"
        />
      </div>

      <span class="entry-packs-container__totals-counter">
        {{ entriesCount }} / {{ MAX_ENTRY_TYPES }}
      </span>
    </div>

    <div class="entry-packs-editor__buttons">
      <m-button-submit
        class="mr-15"
        :disabled="loading || 'EntryPacksPack'"
        @click="onSubmit"
      />

      <m-button-delete
        disabled="EntryPacksPack"
        @yes="onDelete"
      />
    </div>
  </div>
</template>

<script>
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MTextarea from '@/vue_present/_base/inputs/MTextarea/MTextarea.vue'
import EntryTypesSearchAdd from '@/vue_present/Reuse/EntryTypes/EntryTypesSearchAdd/EntryTypesSearchAdd.vue'
import MButtonSubmit from '@/vue_present/_base/buttons/MButtonSubmit/MButtonSubmit.vue'
import { EntryPackPresenter } from '@/vue_apps/catalogs_root/EntryPacks/api/EntryPackPresenter'
import { EntryPack } from '@/vue_apps/catalogs_root/EntryPacks/entities/EntryPack'
import EntryPacksEntryType from '@/vue_apps/catalogs_root/EntryPacks/components/EntryPacksEditor/EntryPacksEntry.vue'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import { GLOBAL_DOUBLE_DEBOUNCE_DEFAULT } from '@/_global_scripts/GLOBAL_CONSTS'
import MButtonDelete from '@/vue_present/_base/buttons/MButtonDelete/MButtonDelete.vue'

const MAX_ENTRY_TYPES = 50

export default {
  name: 'EntryPacksEditor',
  components: {
    MButtonDelete,
    MIcon,
    EntryPacksEntryType,
    MButtonSubmit,
    EntryTypesSearchAdd,
    MTextarea,
    MInput,
  },

  mixins: [ValidationParentMixin, SpinnerHolder],

  props: {
    id: { type: Number, required: true },
  },

  emits: [
    'close',
    'onSubmit',
    'onDelete',
  ],

  data () {
    return {
      entryPack: new EntryPack(),
      MAX_ENTRY_TYPES,
    }
  },

  computed: {
    totals () {
      const { totalAmount, totalSum } = this.entryPack.entries

      return t('entryPacks.totalsTemplate', { totalAmount, totalSum })
    },

    entriesCount () {
      return this.entryPack.entries.items.length
    },

    canAddEntryType () {
      return this.entriesCount < MAX_ENTRY_TYPES
    },
  },

  watch: {
    id () {
      this.init()
    },
  },

  created () {
    this.init()
  },

  methods: {
    init () {
      setTimeout(() => this.fetchEntryPack())
    },

    async fetchEntryPack () {
      const config = { data: { showAllowedClinics: true } }
      const response = await new EntryPackPresenter().fetch(this.id, config)
      if (response.errors) { return }

      this.entryPack = new EntryPack(response)
    },

    async onDelete () {
      const response = await new EntryPackPresenter().destroy(this.id)
      if (response?.errors) { return }
      this.$emit('onDelete', this.id)
    },

    async onSubmit () {
      if (this.hasErrors()) { return }

      const response = await this.withSpinner(
        new EntryPackPresenter().submit(this.entryPack),
        undefined,
        GLOBAL_DOUBLE_DEBOUNCE_DEFAULT
      )
      if (response.errors) { return this.resetValidations(response.errors) }

      this.entryPack.entries.clearDestroyed()
      this.entryPack.entries.reindex(response.entries)

      const { id, name } = response
      this.$emit('onSubmit', { id, name })
    },

    onEntryTypeAdd (entryType) {
      if (!this.$security.canManageEntryPacksPack) { return }
      if (!this.canAddEntryType) {
        return Utils.reportError(
          'EntryPacksEditor:onEntryTypeAdd',
          t('entryPacks.availableMessage', { count: MAX_ENTRY_TYPES })
        )()
      }

      this.entryPack.entries.appendEntryType(entryType)
      Utils.reportSuccess(t('entryPacks.entryTypeAdd'))()
    },
  },
}
</script>
