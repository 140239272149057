export const getRoutesWithoutLast = (routes = []) => {
  return routes.slice(0, -1)
}

export const getRouteParamsMapper = (routes) => {
  return routes.map((route) => ({
    ...route,
    id: route.name,
    name: route.name,
    title: route.meta.label,
  }))
}
