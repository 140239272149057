import { sortBy } from 'lodash'

export const addItem = (items, newItem) => {
  return sortBy([...items, newItem], ['title'])
}

export const updateItem = (items, updatedItem) => {
  const item = items.find((item) => +item.id === +updatedItem.id)
  if (!item) { return }
  item.title = updatedItem.title
  item.phone = updatedItem.phone
}

export const destroyItem = (items, itemId) => {
  return items.filter((item) => +item.id !== +itemId)
}
